.c-404 {
}

.c-404__inner {
  text-align: center;
  padding-top: 7rem;
  padding-bottom: 7rem;

  @include bp(medium) {
    padding-top: 13rem;
    padding-bottom: 13rem;
  }

  @include bp(xlarge) {
    padding-top: 20rem;
    padding-bottom: 20rem;
  }
}

.c-404__message {
  display: block;
  font-family: National, serif;
  font-weight: 400;
  font-size: 2rem;

  @include bp(medium) {
    font-size: 2.5rem;
  }
}

.c-404__back {
  margin-top: 40px;
}
