.c-gmap-category {
  margin: 0;
  list-style: none;
}

.c-gmap-category__info {
  display: flex;
  align-items: center;
  padding: 1.6rem 0;
  font-weight: 400;
  text-transform: uppercase;
  cursor: pointer;
}

.c-gmap-category__state-icon {
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  margin-left: auto;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    display: block;
    width: 100%;
    height: 2px;
    background-color: $color-text;
  }

  &::after {
    transform: rotate(90deg);
  }

  .c-gmap-category__info.is-active &::after {
    transform: rotate(0deg);
  }
}

.c-gmap-category__item {
  margin: 0;
  border-top: 1px solid $color-dusty-grey;

  &:last-child {
    border-bottom: 1px solid $color-dusty-grey;
  }
}

.c-gmap-category__name {
  margin-bottom: 0;
}

.c-gmap-category__bullet {
  width: 25px;
  height: 25px;
  margin-right: 1rem;
  border-radius: 50%;
}

.c-gmap-category__inner {
  margin: 0;
  padding-bottom: 1.6rem;
  font-size: 1.4rem;
  line-height: 1.35em;
  list-style: none;
}

.c-gmap-category__subitem-label {
  padding-right: 1.4rem;
  padding-left: 2px;
  font-size: 1.4rem;
  font-weight: 600;
}

.c-gmap-category__container {
  height: 0;
  overflow: hidden;
  transition: height 0.3s ease;
}
