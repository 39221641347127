.c-cross {
  overflow: hidden;
}

.c-cross__inner {
  background: $color-pampas;
  padding-top: 3.5rem;
  padding-bottom: 4.2rem;

  @include bp(medium) {
    padding-top: 7rem;
    padding-bottom: 10rem;
  }

  @include bp(large) {
    padding-top: 13rem;
    padding-bottom: 22rem;
  }
}

.c-cross__header {
  position: relative;
  z-index: 1;
}

.c-cross__intro {
  font-family: Canela, serif;
  color: $color-aztec;
  text-align: center;
  width: 61rem;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  font-size: 1.8rem;
  line-height: 2.4rem;
  letter-spacing: 0.3px;

  @include bp(medium) {
    font-size: 2.7rem;
    line-height: 4.2rem;
    letter-spacing: 0.5px;
  }
}

.c-cross__images {
  position: relative;

  @include bp(large) {
    margin-top: -4.5rem;
  }
}

.c-cross__image-main {
}

.c-cross__overlays {
  position: absolute;
  top: -8.8333vw;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.c-cross__arrows {
  display: none;

  @include bp(small) {
    display: block;
    position: absolute;
    z-index: 1;
    left: $size-padding;
    right: $size-padding;
    margin: -2.5rem auto 0;
    max-width: $size-wrapper--large;
  }

  @include bp(xlarge) {
    left: $size-padding--medium;
    right: $size-padding--medium;
    margin: -5.8vw auto 0;
  }
}

.c-cross__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  transition-property: opacity, visibility;
  transition-duration: 0.3s;

  &.js-active {
    opacity: 1;
    visibility: visible;
  }
}

.c-cross__image-note {
  display: block;
  margin: 2rem 0 3rem;
  opacity: 0.58;
  font-size: 1.5rem;
  color: $color-aztec;
  text-align: center;

  @include bp(medium) {
    text-align: left;
    margin-left: 21%;
    padding-left: 2rem;
    width: auto;
  }

  @include bp(large) {
    margin-bottom: 8rem;
  }
}

.c-cross__bottom {
  @include bp(large) {
    display: flex;
    justify-content: space-between;
    width: 130rem;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}

.c-cross__description {
  display: none;

  @include bp(small) {
    display: block;
    width: 41.5rem;
    max-width: 100%;
    font-weight: 300;
    font-size: 1.5rem;
    color: $color-aztec;
    letter-spacing: 0;
    line-height: 2.1rem;
    margin-bottom: 3rem;
  }

  @include bp(large) {
    flex: 0 1 41.5rem;
    margin-bottom: 0;
    margin-right: 3rem;
  }
}

.c-cross__navigations {
  display: none;

  @include bp(small) {
    display: flex;
    justify-content: space-between;
    width: 64rem;
    max-width: 100%;
  }

  @include bp(large) {
    flex: 0 1 64rem;
  }
}

.c-cross-navigation {
  list-style: none;
  margin-bottom: 0;
  margin-left: 0;
}

.c-cross-navigation__title {
  display: block;
  font-weight: 400;
  font-size: 1.5rem;
  color: $color-aztec;
  letter-spacing: 1.15px;
  margin-bottom: 1.5rem;
  text-transform: uppercase;

  @include bp(large) {
    margin-bottom: 2.5rem;
  }
}

.c-cross-navigation__li {
  font-weight: 400;
  font-size: 1.5rem;
  color: $color-aztec;
  letter-spacing: 0;
  line-height: 2.1rem;
  margin-bottom: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:last-child {
    margin-bottom: 0;
  }

  @include bp(large) {
    margin-bottom: 2rem;
  }
}

.c-cross-navigation__li-circle {
  display: inline-block;
  border: 1px solid $color-aztec;
  border-radius: 50%;
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 5px;
  margin-top: 3px;
  transition: background-color 0.3s;

  .js-active & {
    background: $color-aztec;
  }
}

.c-cross-navigation__li-text {
  font-weight: 400;

  &::after {
    display: block;
    content: attr(title);
    font-weight: bold;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }

  .js-active & {
    font-weight: 600;
  }
}

.c-cross-slider {
  list-style: none;
  margin: 3rem 5rem 0;

  @include bp(small) {
    display: none;
  }

  .flickity-prev-next-button {
    background: $color-pampas;

    &.previous {
      left: -5rem;
    }

    &.next {
      right: -5rem;
    }
  }
}

.c-cross-slide {
  margin: 0;
  width: 100%;
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
}

.c-cross-slide__group {
  display: block;
  text-align: center;
  font-weight: 400;
  font-size: 1.3rem;
  color: $color-aztec;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.c-cross-slide__content {
  text-align: center;
  font-weight: 300;
  font-size: 1.3rem;
  color: $color-aztec;
  letter-spacing: 0;
  line-height: 1.9rem;
}

.c-cross-slide__number {
  font-weight: 600;
  margin-right: 12px;
  position: relative;
  bottom: 1px;
}

.c-cross-slide__li-text {
}
