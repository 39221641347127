.c-responsive-image__inner {
  position: relative;
  height: 0;
  width: 100%;

  .c-responsive-image--full & {
    min-height: 100%;
  }
}

.c-responsive-image__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-family: 'object-fit: cover; object-position: center;';
  object-fit: cover;
}
