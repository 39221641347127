.c-hero-with-shape {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-top: 60px;
  min-height: calc(100vh - 60px);

  @include bp(large) {
    margin-top: 83px;
    min-height: calc(100vh - 83px);
  }

  &--decorated {
    background: linear-gradient(
      to top,
      rgba($color-black, 0.65),
      transparent 15%
    );
  }
}

.c-hero-with-shape__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover; object-position: center;';
}

.c-hero-with-shape__content {
  position: relative;
  z-index: 1;
  width: 78rem;
  max-width: 100%;
  color: $color-white;
  text-align: center;
}

.c-hero-with-shape__quote {
  font-size: 1.8rem;
  line-height: 2.4rem;
  letter-spacing: 0.3px;
  font-weight: 200;
  margin-bottom: 0;

  @include bp(small) {
    font-size: 2.2rem;
    line-height: 3rem;
  }

  @include bp(medium) {
    font-size: 2.7rem;
    line-height: 4.2rem;
    letter-spacing: 0.5px;
  }
}

.c-hero-with-shape__author {
  display: block;
  font-family: National, serif;
  font-weight: 300;
  font-size: 1.3rem;
  letter-spacing: 1.15px;
  text-transform: uppercase;
  margin-top: 3rem;

  @include bp(small) {
    font-size: 1.5rem;
    margin-top: 6.5rem;
  }
}
