.c-living-carousel {
  margin-top: 5.5rem;
  margin-bottom: 5.5rem;

  @include bp(medium) {
    margin-top: 11.5rem;
    margin-bottom: 14rem;
  }
}

.c-living-carousel__inner {
}

.c-living-carousel__top {
  text-align: left;
  margin-bottom: 3rem;

  @include bp(small) {
    margin-bottom: 6rem;
  }

  @include bp(medium) {
    text-align: center;
    margin-bottom: 12rem;
  }
}

.c-living-carousel__title {
  font-size: 1.8rem;
  letter-spacing: 0.3px;
  line-height: 2.4rem;
  font-family: Canela, serif;
  color: $color-aztec;
  font-weight: 200;
  margin-bottom: 0;
  @include bp(small) {
    font-size: 2.3rem;
    line-height: 3rem;
  }
  @include bp(medium) {
    font-size: 2.7rem;
    letter-spacing: 0.5px;
    line-height: 4.2rem;
  }
}

.c-living-carousel__line {
  display: none;
  @include bp(medium) {
    display: inline-block;
    height: 33px;
    width: 1px;
    background-color: #979797;
    margin-top: 20px;
    margin-bottom: 35px;
  }
}

.c-living-carousel-nav {
  display: none;
  @include bp(medium) {
    display: flex;
    justify-content: center;
    list-style: none;
    margin: 0;
    text-transform: uppercase;
  }
}

.c-living-carousel-nav__item {
  flex: 0 0 8.5rem;
  margin: 0 0.7rem;
  cursor: pointer;
}

.c-living-carousel-nav__dot {
  display: block;
  border: 1px solid $color-aztec;
  border-radius: 50%;
  width: 1.7rem;
  height: 1.7rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  transition: background-color 0.3s;

  .js-active & {
    background-color: $color-aztec;
  }
}

.c-living-carousel-nav__title {
  font-family: National, serif;
  font-weight: 400;
  font-size: 1.5rem;
  color: $color-aztec;
  letter-spacing: 1.15px;
}

.c-living-carousel__bottom {
}

.c-living-slider {
  list-style: none;
  margin: 0;

  .flickity-page-dots {
    bottom: -30px;

    .dot {
      background: transparent;
      border: 1px solid $color-grey;
      opacity: 1;

      &.is-selected {
        background: $color-very-light-grey;
      }
    }

    @include bp(small) {
      display: none;
    }
  }

  .flickity-prev-next-button.previous {
    display: none;

    @include bp(medium) {
      display: block;
      left: $size-padding;
    }

    @include bp(large) {
      left: $size-padding--medium;
    }
  }

  .flickity-prev-next-button.next {
    display: none;

    @include bp(medium) {
      display: block;
      right: $size-padding;
    }

    @include bp(large) {
      right: $size-padding--medium;
    }
  }
}

.c-living-slide {
  width: 85%;
  padding: 1.5rem 1rem;
  border: 1px solid $color-very-light-grey;
  margin-left: 0.75rem;
  margin-right: 0.75rem;

  @include bp(small) {
    display: block;
    padding: 2rem 2.5rem;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    width: 75%;
  }

  @include bp(medium) {
    padding: 3rem 3.5rem;
  }

  @include bp(large) {
    width: 66.66%;
    margin-left: 30px;
    margin-right: 30px;
  }
}

.c-living-slide__top {
}

.c-living-slide__image {
  width: 100%;
}

.c-living-slide__bottom {
  display: block;
  font-family: National, serif;
  color: $color-aztec;
  margin-top: 2rem;

  @include bp(small) {
    margin-top: 3rem;
  }

  @include bp(medium) {
    display: flex;
  }
}

.c-living-slide__content {
  flex: 0 0 auto;

  @include bp(medium) {
    width: 50%;
    padding-right: 6rem;
  }
}

.c-living-slide__title {
  font-weight: 400;
  letter-spacing: 1.15px;
  text-transform: uppercase;
  margin-bottom: 0;

  &.c-living-slide__title--mobile {
    display: block;
    margin-bottom: 1rem;
    font-size: 13px;

    @include bp(small) {
      display: none;
    }
  }

  &.c-living-slide__title--desktop {
    display: none;

    @include bp(small) {
      display: block;
      font-size: 1.5rem;
    }
  }
}

.c-living-slide__description {
  font-size: 1.3rem;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 1.9rem;
  text-align: center;

  @include bp(small) {
    text-align: left;
    font-size: 1.5rem;
    line-height: 2.1rem;
  }
}

.c-living-slide__features {
  flex: 0 0 auto;
  list-style: none;
  margin: 3.6rem 0 0 0;

  @include bp(medium) {
    width: 50%;
    padding-left: 6rem;
  }
}

.c-living-slide__feature {
  text-align: center;
  margin-bottom: 3.3rem;

  &:last-child {
    margin-bottom: 2.3rem;
  }

  @include bp(small) {
    display: flex;
    text-align: left;
    margin: 0;
  }
}

.c-living-slide__feature-name {
  flex: 0 0 10.5rem;
  font-weight: 400;
  font-size: 1.3rem;
  letter-spacing: 1px;
  line-height: 1.6rem;
  text-transform: uppercase;
  display: block;
  margin-bottom: 5px;

  @include bp(small) {
    font-size: 1.2rem;
    line-height: 1.7rem;
    margin-bottom: 0;
  }
}

.c-living-slide__feature-content {
  flex: 1 1 auto;
  font-weight: 300;
  font-size: 1.5rem;
  line-height: 1.6rem;
  letter-spacing: 0;
  display: block;

  @include bp(small) {
    font-size: 1.2rem;
  }
}
