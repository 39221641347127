@charset "UTF-8";
/*
  Project: Babylon
  Author: Aleksandra Worhacz
 */
/* Colors
   ========================================================================== */
/* Fonts
   ========================================================================== */
/* Breakpoints
   ========================================================================== */
@keyframes dotPulse {
  0% {
    transform: scale(1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(1.5);
  }
}

@keyframes fadeOutPreloader {
  0% {
    opacity: 1;
    visibility: visible;
  }
  20% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes fadeInPreloader {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

/*! Flickity v2.2.1
https://flickity.metafizzy.co
---------------------------------------------- */
.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus {
  outline: none;
}

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* draggable */
.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

/* ---- flickity-button ---- */
.flickity-button {
  position: absolute;
  border: none;
  color: #333;
  background: transparent;
}

.flickity-button:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-size: contain;
}

.flickity-button svg {
  display: none;
}

.flickity-button:hover {
  cursor: pointer;
}

.flickity-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #19F;
}

.flickity-button:active {
  opacity: 0.6;
}

.flickity-button:disabled {
  opacity: 0.3;
  cursor: auto;
  /* prevent disabled button from capturing pointer up event. #716 */
  pointer-events: none;
}

.flickity-button-icon {
  fill: currentColor;
}

/* ---- previous/next buttons ---- */
.flickity-prev-next-button {
  top: 50%;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  /* vertically center */
  transform: translateY(-50%);
}

.flickity-prev-next-button.previous {
  left: 0;
}

.flickity-prev-next-button.previous:before {
  background-image: url("../assets/images/reviews/arrow-previous.svg");
}

.flickity-prev-next-button.next {
  right: 0;
}

.flickity-prev-next-button.next:before {
  background-image: url("../assets/images/reviews/arrow-next.svg");
}

/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px;
}

.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px;
}

.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%;
}

/* ---- page dots ---- */
.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;
}

.flickity-rtl .flickity-page-dots {
  direction: rtl;
}

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer;
}

.flickity-page-dots .dot.is-selected {
  opacity: 1;
}

.gm-style .gm-style-iw-c {
  border-radius: 0;
  box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.1);
}

button.gm-ui-hover-effect {
  visibility: hidden;
}

/* ==========================================================================
   #BOX-SIZING
   ========================================================================== */
/**
 * More sensible default box-sizing:
 * css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
 */
html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

/* ==========================================================================
   #FONT-FACE
   ========================================================================== */
/**
 * Add font-display: swap to font-face blocks here
 * https://www.zachleat.com/web/comprehensive-webfonts/#font-display
 */
@font-face {
  font-family: "Canela";
  src: url("../assets/fonts/Canela-Light-Web.woff") format("woff");
  font-style: normal;
  font-weight: 200;
  font-display: swap;
}

@font-face {
  font-family: "National";
  src: url("../assets/fonts/NationalWeb-Thin.woff") format("woff");
  font-style: normal;
  font-weight: 100;
  font-display: swap;
}

@font-face {
  font-family: "National";
  src: url("../assets/fonts/NationalWeb-Book.woff") format("woff");
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "National";
  src: url("../assets/fonts/NationalWeb-BookItalic.woff") format("woff");
  font-style: italic;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "National";
  src: url("../assets/fonts/NationalWeb-Regular.woff") format("woff");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "National";
  src: url("../assets/fonts/NationalWeb-Semibold.woff") format("woff");
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}

/* ==========================================================================
   #NORMALIZE
   ========================================================================== */
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Correct the line height in all browsers.
     * 2. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  line-height: 1.15;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
}

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0;
}

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block;
}

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block;
}

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px;
}

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}

/**
     * Add the correct display in IE.
     */
main {
  display: block;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */
}

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Chrome 57- and Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */
}

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit;
}

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic;
}

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000;
}

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%;
}

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block;
}

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none;
}

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden;
}

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */
}

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible;
}

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none;
}

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible;
}

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */
}

[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

/**
     * Correct the padding in Firefox.
     */
fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */
}

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */
}

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto;
}

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block;
}

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item;
}

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block;
}

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block;
}

/**
     * Add the correct display in IE.
     */
template {
  display: none;
}

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none;
}

/* ==========================================================================
   #RESET
   ========================================================================== */
/**
 * A very simple reset that sits on top of Normalize.css.
 */
body,
h1, h2, h3, h4, h5, h6,
blockquote, p, pre,
dl, dd, ol, ul,
figure,
hr,
fieldset, legend {
  margin: 0;
  padding: 0;
}

/**
 * Remove trailing margins from nested lists.
 */
li > ol,
li > ul {
  margin-bottom: 0;
}

/**
 * Remove default table spacing.
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/**
 * 1. Reset Chrome and Firefox behaviour which sets a `min-width: min-content;`
 *    on fieldsets.
 */
fieldset {
  min-width: 0;
  /* [1] */
  border: 0;
}

/* ==========================================================================
   #SHARED
   ========================================================================== */
/**
 * Shared declarations for certain elements.
 */
/**
 * Always declare margins in the same direction:
 * csswizardry.com/2012/06/single-direction-margin-declarations
 */
address,
h1, h2, h3, h4, h5, h6,
blockquote, p, pre,
dl, ol, ul,
figure,
hr,
table,
fieldset {
  margin-bottom: 1.3rem;
}

/**
 * Consistent indentation for lists.
 */
dd, ol, ul {
  margin-left: 1rem;
}

/* ==========================================================================
   #BLOCKQUOTES
   ========================================================================== */
blockquote {
  quotes: '“' '”' '‘' '’';
  font-style: italic;
}

blockquote p:first-child:before {
  content: open-quote;
}

blockquote p:last-child:after {
  content: close-quote;
}

/* ==========================================================================
   #HEADINGS
   ========================================================================== */
/**
 * Simple default styles for headings 1 through 6. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http://csswizardry.com/2016/02/managing-typography-on-large-apps/).
 */
h1 {
  font-family: "Canela";
  font-weight: 200;
  font-size: 2.25rem;
}

h2 {
  font-size: 1.75rem;
  font-weight: 300;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.25rem;
}

h5 {
  font-size: 1.125rem;
}

h6 {
  font-size: 1rem;
}

/* ==========================================================================
   #HR
   ========================================================================== */
hr {
  display: block;
}

/* ==========================================================================
   #PAGE
   ========================================================================== */
/**
 * Simple page-level setup.
 *
 * 1. Set the default `font-size` and `line-height` for the entire project.
 * 2. Force scrollbars to always be visible to prevent awkward ‘jumps’ when
 *    navigating between pages that do/do not have enough content to produce
 *    scrollbars naturally.
 * 3. Ensure the page always fills at least the entire height of the viewport.
 */
html {
  background: #fff;
  color: #172724;
  font-family: "National";
  font-size: 0.625rem;
  /* [1] */
  /* 10px */
  line-height: 1.5;
  /* [1] */
  min-height: 100%;
  /* [3] */
  overflow-y: scroll;
  /* [2] */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  /* stylelint-disable selector-no-qualifying-type */
}

html.js-push-menu--active {
  overflow: hidden;
}

/* ==========================================================================
   #IMAGES
   ========================================================================== */
/**
 * 1. Fluid images for responsive purposes.
 * 2. Offset `alt` text from surrounding copy.
 * 3. Setting `vertical-align` removes the whitespace that appears under `img`
 *    elements when they are dropped into a page as-is. Safer alternative to
 *    using `display: block;`.
 */
img {
  font-style: italic;
  /* [2] */
  max-width: 100%;
  /* [1] */
  vertical-align: middle;
  /* [3] */
}

/**
 * 1. If a `width` and/or `height` attribute have been explicitly defined, let’s
 *    not make the image fluid.
 */
img[width],
img[height] {
  max-width: none;
}

/* ==========================================================================
   #LINKS
   ========================================================================== */
a {
  color: #666;
  text-decoration: underline;
}

/* ==========================================================================
   #LISTS
   ========================================================================== */
li {
  margin-bottom: 0.5rem;
}

li > ul,
li > ol {
  margin-bottom: 0;
  margin-left: 1rem;
  margin-top: 0.5rem;
}

dt {
  font-weight: bold;
}

dd {
  margin-bottom: 1rem;
  margin-left: 1rem;
}

/* ==========================================================================
   #TABLES
   ========================================================================== */
/**
 * 1. Ensure tables fill up as much space as possible.
 */
table {
  width: 100%;
  /* [1] */
}

.o-container {
  padding-left: 2rem;
  padding-right: 2rem;
}

@media (min-width: 768px) {
  .o-container {
    padding-left: 6rem;
    padding-right: 6rem;
  }
}

.o-link {
  display: inline-block;
  font-size: 1.5rem;
  text-transform: uppercase;
  text-decoration: none;
  position: relative;
  color: inherit;
}

.o-link::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -2px;
  display: block;
  width: 100%;
  height: 1px;
  background-color: #172724;
  transform: translateY(2px);
  opacity: 0;
  transition-property: transform, opacity;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  will-change: transform, opacity;
}

.o-link:hover {
  color: inherit;
}

.o-link:hover::after {
  transform: translateY(0);
  opacity: 1;
}

.o-link-persist {
  display: inline-block;
  font-size: 1.5rem;
  text-transform: uppercase;
  text-decoration: none;
  position: relative;
  padding-left: 1rem;
  padding-right: 1rem;
}

.o-link-persist::after {
  content: '';
  display: block;
  height: 1px;
  width: 100%;
  margin: auto;
  background-color: #999;
  position: absolute;
  left: 50%;
  bottom: -8px;
  transform: translateX(-50%) scaleX(0);
  transition: transform 0.5s cubic-bezier(0.28, 0, 0.18, 1), background 1s cubic-bezier(0.28, 0, 0.18, 1);
}

.o-link-persist:hover {
  opacity: 1;
}

.o-link-persist:hover::after {
  transition: transform 0.5s cubic-bezier(0.28, 0, 0.18, 1) 0.2s;
  transform: translateX(-50%) scaleX(1);
}

.o-section {
  position: relative;
}

.o-section--screen {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 100vh;
  padding-top: 0;
  padding-bottom: 0;
}

@media (min-width: 1024px) {
  .o-section--desktop-screen {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    min-height: 100vh;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.o-section--white {
  background-color: #fff;
}

.o-section--white-to-primary {
  background-color: #d0d4cb;
}

@media (min-width: 768px) {
  .o-section--white-to-primary {
    background-color: #fff;
  }
}

.o-section--primary-to-white {
  background-color: #d0d4cb;
}

@media (min-width: 768px) {
  .o-section--primary-to-white {
    background-color: #fff;
  }
}

.o-section--primary {
  background-color: #d0d4cb;
}

.o-section--dark-green {
  background-color: #172724;
}

.o-section--light-brown {
  background-color: #f2efe7;
}

.o-section--pampas {
  background-color: #f3f1eb;
}

.o-section--grey-nurse {
  background-color: #e0e3dd;
}

.o-section__top {
  flex: 0 0 auto;
  width: 100%;
  overflow: hidden;
}

.o-section__bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.o-section__middle {
  flex: 1 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.o-section__wrapper {
  display: flex;
  flex-direction: column;
}

.o-section__wrapper > .o-section {
  width: 100%;
}

.o-spacer {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.o-spacer--full {
  padding-top: 0;
  padding-bottom: 0;
}

@media (min-width: 1440px) {
  .o-spacer--full {
    padding-top: 11.5rem;
    padding-bottom: 11.5rem;
  }
}

/* ==========================================================================
   #WRAPPER
   ========================================================================== */
/**
 * Page-level constraining and wrapping elements.
 */
.o-wrapper {
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  max-width: 1360px;
  padding-left: 2rem;
  padding-right: 2rem;
}

@media (min-width: 768px) {
  .o-wrapper {
    padding-left: 6rem;
    padding-right: 6rem;
  }
}

.o-wrapper--large {
  max-width: 1640px;
}

.o-wrapper--no-padding {
  padding-left: 0;
  padding-right: 0;
}

.c-404__inner {
  text-align: center;
  padding-top: 7rem;
  padding-bottom: 7rem;
}

@media (min-width: 768px) {
  .c-404__inner {
    padding-top: 13rem;
    padding-bottom: 13rem;
  }
}

@media (min-width: 1280px) {
  .c-404__inner {
    padding-top: 20rem;
    padding-bottom: 20rem;
  }
}

.c-404__message {
  display: block;
  font-family: National, serif;
  font-weight: 400;
  font-size: 2rem;
}

@media (min-width: 768px) {
  .c-404__message {
    font-size: 2.5rem;
  }
}

.c-404__back {
  margin-top: 40px;
}

/* ==========================================================================
   #BUTTONS
   ========================================================================== */
/**
 * This is an example component. Extend inuitcss by building your own components
 * that make up your UI. Component classes are prefixed with a `c-`.
 */
/**
 * 1. Allow us to style box model properties.
 * 2. Line different sized buttons up a little nicer.
 * 3. Make buttons inherit font styles (often necessary when styling `input`s as
 *    buttons).
 * 4. Reset/normalize some styles.
 * 5. Force all button-styled elements to appear clickable.
 */
.c-btn {
  display: inline-block;
  /* [1] */
  padding: 1.7rem 4.1rem;
  margin: 0;
  /* [4] */
  font: inherit;
  /* [3] */
  font-size: 1.5rem;
  letter-spacing: 1.15px;
  text-align: center;
  /* [4] */
  vertical-align: middle;
  /* [2] */
  cursor: pointer;
  /* [5] */
  text-transform: uppercase;
  text-decoration: none;
  background-color: #fff;
  color: #172724;
  transition: all 300ms ease-in-out;
}

/* ==========================================================================
   #COMMENT FORM
   Here we are using default HTML structure and classes,
   to create custom ones use comment_form_defaults filter
   ========================================================================== */
.c-comment-form {
  margin-top: 2em;
}

.c-comment-form label {
  display: block;
}

.c-comment-form textarea {
  max-width: 100%;
}

.comment-reply-title {
  margin-bottom: 0.25em;
}

/* ==========================================================================
   #COMMENT
   ========================================================================== */
.c-comment {
  margin-bottom: 1em;
}

.c-comment__author {
  font-size: 1rem;
  margin-bottom: 0.25em;
}

.c-comment__moderation {
  font-style: italic;
  margin-bottom: 0.5em;
}

/* stylelint-disable */
.c-contact {
  background-color: #f3f1eb;
}

.c-contact__inner {
  padding-top: 13.5rem;
  padding-bottom: 5rem;
}

@media (min-width: 768px) {
  .c-contact__inner {
    padding-top: 15rem;
  }
}

@media (max-width: 767.98px) {
  .c-contact__inner {
    width: 500px;
    max-width: 100%;
  }
}

.c-contact__top {
  margin-bottom: 5rem;
}

@media (min-width: 768px) {
  .c-contact__top {
    margin-bottom: 7.5rem;
  }
}

.c-contact__title {
  font-size: 1.8rem;
  line-height: 2.4rem;
  letter-spacing: 0.3px;
}

@media (min-width: 480px) {
  .c-contact__title {
    font-size: 2.2rem;
    line-height: 3rem;
    text-align: center;
  }
}

@media (min-width: 768px) {
  .c-contact__title {
    font-size: 2.7rem;
    line-height: 4.2rem;
    letter-spacing: 0.5px;
  }
}

.c-contact-confirmation {
  background: #172724;
  color: #fff;
  padding-top: 4rem;
  padding-bottom: 4rem;
  text-align: center;
}

@media (min-width: 480px) {
  .c-contact-confirmation {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
}

@media (min-width: 768px) {
  .c-contact-confirmation {
    padding-top: 12rem;
    padding-bottom: 11rem;
  }
}

.c-contact-confirmation__content {
  width: 340px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.c-contact-confirmation__title {
  font-size: 2.7rem;
  line-height: 4.2rem;
  margin-bottom: 2.5rem;
}

.c-contact-confirmation__quote {
  margin-bottom: 5rem;
  display: block;
  font-style: italic;
  font-size: 1.6rem;
  line-height: 1.9rem;
}

.c-contact-confirmation__description {
  font-size: 1.5rem;
  line-height: 2.1rem;
  margin-bottom: 4rem;
}

.c-contact-confirmation__link {
  color: #fff;
}

.c-contact__note {
  text-align: center;
  font-weight: 300;
  font-size: 1.3rem;
  line-height: 1.6rem;
  width: 480px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  opacity: 0.6;
}

.c-contact__footer {
  margin-top: 3rem;
  border-top: 1px solid #ccc;
  padding-top: 3.5rem;
}

@media (min-width: 768px) {
  .c-contact__footer {
    margin-top: 8rem;
    padding-top: 2rem;
    display: flex;
    justify-content: center;
  }
}

.c-contact-place {
  font-size: 1.5rem;
  line-height: 2.1rem;
  font-weight: 300;
}

@media (min-width: 768px) {
  .c-contact-place {
    text-align: center;
    margin-left: 6rem;
    margin-right: 6rem;
  }
}

.c-contact-place:first-child {
  margin-bottom: 3rem;
}

@media (min-width: 768px) {
  .c-contact-place:first-child {
    margin-bottom: 0;
  }
}

.c-contact-place > span {
  display: block;
}

.c-contact-place__title {
  text-transform: uppercase;
  font-weight: 400;
}

.c-contact-place__country {
  text-transform: uppercase;
}

div.wpforms-container .wpforms-form .wpforms-field-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: -2rem;
  margin-right: -2rem;
}

div.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field {
  flex: 0 0 auto;
  width: 100%;
  margin: 0;
  padding: 0 2rem 2.5rem;
}

@media (min-width: 768px) {
  div.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field {
    width: 50%;
    padding: 0 2rem 3.5rem;
  }
}

@media (min-width: 1024px) {
  div.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field {
    width: 33.33%;
  }
}

div.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field input,
div.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field select,
div.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field label {
  background-color: transparent;
  max-width: none;
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 0;
  font-size: 1.3rem;
  line-height: 1.7rem;
  font-family: National, serif;
  font-weight: 300;
  color: #172724;
}

@media (min-width: 480px) {
  div.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field input,
  div.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field select,
  div.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field label {
    font-size: 1.5rem;
    line-height: 2.1rem;
  }
}

div.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field input::placeholder,
div.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field select::placeholder,
div.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field label::placeholder {
  opacity: 1;
  color: #172724;
}

div.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field input[type='text'],
div.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field input[type='email'],
div.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field select {
  border-bottom: 1px solid #ccc;
}

div.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field select {
  -webkit-appearance: none;
  background-image: url("../assets/images/contact/chevron-down.svg");
  background-repeat: no-repeat;
  background-position: center right;
  background-size: 20px 10px;
}

div.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field select::-ms-expand {
  display: none;
}

div.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field input[type='checkbox'] {
  display: none;
}

div.wpforms-container
.wpforms-form
.wpforms-field-container
.wpforms-field-checkbox
ul
li {
  position: relative;
}

div.wpforms-container
.wpforms-form
.wpforms-field-container
.wpforms-field-checkbox
ul
li::before, div.wpforms-container
.wpforms-form
.wpforms-field-container
.wpforms-field-checkbox
ul
li::after {
  position: absolute;
  pointer-events: none;
}

div.wpforms-container
.wpforms-form
.wpforms-field-container
.wpforms-field-checkbox
ul
li::before {
  display: block;
  content: '';
  width: 2.2rem;
  height: 2.2rem;
  border: 1px solid #ccc;
  z-index: 1;
  background: #f3f1eb;
  left: 0;
  top: 0;
}

div.wpforms-container
.wpforms-form
.wpforms-field-container
.wpforms-field-checkbox
ul
li.wpforms-selected::after {
  content: '';
  display: block;
  width: 7px;
  height: 14px;
  border: solid #999;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  z-index: 2;
  left: 7px;
  top: 3px;
}

div.wpforms-container
.wpforms-form
.wpforms-field-container
.wpforms-field-checkbox
ul
li label {
  padding-left: 3.5rem;
  cursor: pointer;
  display: block;
}

@media (min-width: 768px) {
  div.wpforms-container
.wpforms-form
.wpforms-field-container
.wpforms-field-checkbox
ul
li label {
    padding-left: 5rem;
  }
}

div.wpforms-container .wpforms-form .wpforms-submit-container {
  text-align: center;
}

div.wpforms-container .wpforms-form .wpforms-submit-container button[type='submit'].c-contact-form__submit {
  font-size: 1.3rem;
  line-height: 1.6rem;
  font-family: National, serif;
  background: transparent;
  border: none;
  border-bottom: 1px solid #999;
  text-transform: uppercase;
  padding: 0 1rem 0.2rem;
}

div.wpforms-container .wpforms-form .wpforms-submit-container button[type='submit'].c-contact-form__submit:hover {
  background: transparent;
  border: none;
  border-bottom: 1px solid #999;
}

/* stylelint-enable */
.c-cross {
  overflow: hidden;
}

.c-cross__inner {
  background: #f3f1eb;
  padding-top: 3.5rem;
  padding-bottom: 4.2rem;
}

@media (min-width: 768px) {
  .c-cross__inner {
    padding-top: 7rem;
    padding-bottom: 10rem;
  }
}

@media (min-width: 1024px) {
  .c-cross__inner {
    padding-top: 13rem;
    padding-bottom: 22rem;
  }
}

.c-cross__header {
  position: relative;
  z-index: 1;
}

.c-cross__intro {
  font-family: Canela, serif;
  color: #172724;
  text-align: center;
  width: 61rem;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  font-size: 1.8rem;
  line-height: 2.4rem;
  letter-spacing: 0.3px;
}

@media (min-width: 768px) {
  .c-cross__intro {
    font-size: 2.7rem;
    line-height: 4.2rem;
    letter-spacing: 0.5px;
  }
}

.c-cross__images {
  position: relative;
}

@media (min-width: 1024px) {
  .c-cross__images {
    margin-top: -4.5rem;
  }
}

.c-cross__overlays {
  position: absolute;
  top: -8.8333vw;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.c-cross__arrows {
  display: none;
}

@media (min-width: 480px) {
  .c-cross__arrows {
    display: block;
    position: absolute;
    z-index: 1;
    left: 2rem;
    right: 2rem;
    margin: -2.5rem auto 0;
    max-width: 1640px;
  }
}

@media (min-width: 1280px) {
  .c-cross__arrows {
    left: 6rem;
    right: 6rem;
    margin: -5.8vw auto 0;
  }
}

.c-cross__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  transition-property: opacity, visibility;
  transition-duration: 0.3s;
}

.c-cross__image.js-active {
  opacity: 1;
  visibility: visible;
}

.c-cross__image-note {
  display: block;
  margin: 2rem 0 3rem;
  opacity: 0.58;
  font-size: 1.5rem;
  color: #172724;
  text-align: center;
}

@media (min-width: 768px) {
  .c-cross__image-note {
    text-align: left;
    margin-left: 21%;
    padding-left: 2rem;
    width: auto;
  }
}

@media (min-width: 1024px) {
  .c-cross__image-note {
    margin-bottom: 8rem;
  }
}

@media (min-width: 1024px) {
  .c-cross__bottom {
    display: flex;
    justify-content: space-between;
    width: 130rem;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}

.c-cross__description {
  display: none;
}

@media (min-width: 480px) {
  .c-cross__description {
    display: block;
    width: 41.5rem;
    max-width: 100%;
    font-weight: 300;
    font-size: 1.5rem;
    color: #172724;
    letter-spacing: 0;
    line-height: 2.1rem;
    margin-bottom: 3rem;
  }
}

@media (min-width: 1024px) {
  .c-cross__description {
    flex: 0 1 41.5rem;
    margin-bottom: 0;
    margin-right: 3rem;
  }
}

.c-cross__navigations {
  display: none;
}

@media (min-width: 480px) {
  .c-cross__navigations {
    display: flex;
    justify-content: space-between;
    width: 64rem;
    max-width: 100%;
  }
}

@media (min-width: 1024px) {
  .c-cross__navigations {
    flex: 0 1 64rem;
  }
}

.c-cross-navigation {
  list-style: none;
  margin-bottom: 0;
  margin-left: 0;
}

.c-cross-navigation__title {
  display: block;
  font-weight: 400;
  font-size: 1.5rem;
  color: #172724;
  letter-spacing: 1.15px;
  margin-bottom: 1.5rem;
  text-transform: uppercase;
}

@media (min-width: 1024px) {
  .c-cross-navigation__title {
    margin-bottom: 2.5rem;
  }
}

.c-cross-navigation__li {
  font-weight: 400;
  font-size: 1.5rem;
  color: #172724;
  letter-spacing: 0;
  line-height: 2.1rem;
  margin-bottom: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.c-cross-navigation__li:last-child {
  margin-bottom: 0;
}

@media (min-width: 1024px) {
  .c-cross-navigation__li {
    margin-bottom: 2rem;
  }
}

.c-cross-navigation__li-circle {
  display: inline-block;
  border: 1px solid #172724;
  border-radius: 50%;
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 5px;
  margin-top: 3px;
  transition: background-color 0.3s;
}

.js-active .c-cross-navigation__li-circle {
  background: #172724;
}

.c-cross-navigation__li-text {
  font-weight: 400;
}

.c-cross-navigation__li-text::after {
  display: block;
  content: attr(title);
  font-weight: bold;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}

.js-active .c-cross-navigation__li-text {
  font-weight: 600;
}

.c-cross-slider {
  list-style: none;
  margin: 3rem 5rem 0;
}

@media (min-width: 480px) {
  .c-cross-slider {
    display: none;
  }
}

.c-cross-slider .flickity-prev-next-button {
  background: #f3f1eb;
}

.c-cross-slider .flickity-prev-next-button.previous {
  left: -5rem;
}

.c-cross-slider .flickity-prev-next-button.next {
  right: -5rem;
}

.c-cross-slide {
  margin: 0;
  width: 100%;
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
}

.c-cross-slide__group {
  display: block;
  text-align: center;
  font-weight: 400;
  font-size: 1.3rem;
  color: #172724;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.c-cross-slide__content {
  text-align: center;
  font-weight: 300;
  font-size: 1.3rem;
  color: #172724;
  letter-spacing: 0;
  line-height: 1.9rem;
}

.c-cross-slide__number {
  font-weight: 600;
  margin-right: 12px;
  position: relative;
  bottom: 1px;
}

.c-draft {
  padding-bottom: 7.5rem;
  margin-top: -50px;
}

@media (min-width: 768px) {
  .c-draft {
    margin-top: -100px;
    padding-bottom: 15rem;
  }
}

.c-entry-gate {
  position: relative;
  width: 100%;
}

.c-entry-gate__image {
  opacity: 0.7;
}

.c-entry-gate__btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* ==========================================================================
   #FOOTER
   ========================================================================== */
.c-footer {
  background: #d0d4cb;
}

.c-footer__inner {
  padding-top: 5.5rem;
  padding-bottom: 2.8rem;
}

@media (min-width: 768px) {
  .c-footer__inner {
    padding-top: 9.6rem;
    padding-bottom: 3.6rem;
  }
}

.c-footer-nav {
  display: none;
}

@media (min-width: 768px) {
  .c-footer-nav {
    list-style: none;
    margin: 0 -1.5rem 5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    font-family: Canela, serif;
    font-size: 1.8rem;
    color: #172724;
    letter-spacing: 0.32px;
  }
}

.c-footer-nav__li {
  margin: 0 1.5rem;
}

@media (min-width: 1024px) {
  .c-footer-nav__li {
    margin: 0 2.25rem;
  }
}

.c-footer-nav__a {
  text-decoration: none;
  color: #172724;
  text-transform: none;
  margin-bottom: 1rem;
}

.c-footer-low {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 768px) {
  .c-footer-low {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
}

@media (min-width: 768px) {
  .c-footer-low__item {
    flex: 1 1 10rem;
  }
}

.c-footer-denvell {
  margin-bottom: 1rem;
}

@media (min-width: 768px) {
  .c-footer-denvell {
    margin-bottom: 0;
  }
}

.c-footer-denvell__logo {
  width: 9.6rem;
}

@media (min-width: 768px) {
  .c-footer-denvell__logo {
    width: 11rem;
  }
}

.c-footer-ancient {
  text-align: center;
  order: -1;
  margin-bottom: 6rem;
}

@media (min-width: 768px) {
  .c-footer-ancient {
    order: initial;
    margin-bottom: 0;
  }
}

.c-footer-ancient__logo {
  width: 4.4rem;
  margin-bottom: 1.2rem;
}

.c-footer-ancient__text {
  display: block;
  font-family: Canela;
  font-size: 1.3rem;
  color: #172724;
  letter-spacing: 0.65px;
  text-align: center;
  line-height: 1.9rem;
}

.c-footer-note {
  text-align: center;
  font-family: National, serif;
  color: #172724;
  font-size: 1.3rem;
}

@media (min-width: 768px) {
  .c-footer-note {
    text-align: right;
  }
}

.c-footer-copyright {
  display: block;
  margin-bottom: 1rem;
  line-height: 2.1rem;
}

@media (min-width: 768px) {
  .c-footer-copyright {
    display: inline-block;
    margin-bottom: 0;
  }
}

.c-footer-terms {
  text-decoration: none;
  display: block;
  opacity: 0.6;
  font-weight: 300;
  line-height: 1.6rem;
  font-size: 13px;
}

@media (min-width: 768px) {
  .c-footer-terms {
    display: inline-block;
    font-size: inherit;
  }
}

.c-gmap-category {
  margin: 0;
  list-style: none;
}

.c-gmap-category__info {
  display: flex;
  align-items: center;
  padding: 1.6rem 0;
  font-weight: 400;
  text-transform: uppercase;
  cursor: pointer;
}

.c-gmap-category__state-icon {
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  margin-left: auto;
}

.c-gmap-category__state-icon::before, .c-gmap-category__state-icon::after {
  content: '';
  position: absolute;
  top: 50%;
  display: block;
  width: 100%;
  height: 2px;
  background-color: #172724;
}

.c-gmap-category__state-icon::after {
  transform: rotate(90deg);
}

.c-gmap-category__info.is-active .c-gmap-category__state-icon::after {
  transform: rotate(0deg);
}

.c-gmap-category__item {
  margin: 0;
  border-top: 1px solid #979797;
}

.c-gmap-category__item:last-child {
  border-bottom: 1px solid #979797;
}

.c-gmap-category__name {
  margin-bottom: 0;
}

.c-gmap-category__bullet {
  width: 25px;
  height: 25px;
  margin-right: 1rem;
  border-radius: 50%;
}

.c-gmap-category__inner {
  margin: 0;
  padding-bottom: 1.6rem;
  font-size: 1.4rem;
  line-height: 1.35em;
  list-style: none;
}

.c-gmap-category__subitem-label {
  padding-right: 1.4rem;
  padding-left: 2px;
  font-size: 1.4rem;
  font-weight: 600;
}

.c-gmap-category__container {
  height: 0;
  overflow: hidden;
  transition: height 0.3s ease;
}

.c-gmap {
  display: flex;
  flex-direction: column;
}

@media (min-width: 1280px) {
  .c-gmap {
    flex-direction: row;
  }
}

.c-gmap__map {
  flex-direction: column;
  display: flex;
  width: 100%;
  height: 50rem;
  max-height: 100vh;
}

@media (min-width: 1280px) {
  .c-gmap__map {
    width: 70.778%;
    height: auto;
    min-height: calc(100vh - 83px);
    max-height: none;
    flex: 1 1 auto;
  }
}

.c-gmap__map-embed {
  flex: 1 1 auto;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.c-gmap__location-name {
  font-family: "National";
}

.c-gmap__locations {
  flex: 1 1 auto;
  padding: 4rem 2rem 6rem;
}

@media (min-width: 1280px) {
  .c-gmap__locations {
    padding: 15rem 8rem;
  }
}

.c-gmap-category__subitem {
  display: flex;
  cursor: pointer;
}

.c-gmap-category__subitem-label {
  flex: 0 0 auto;
  width: 2.2em;
  pointer-events: none;
}

.c-gmap-category__subitem-title {
  pointer-events: none;
}

body.home .c-header {
  height: 6rem;
}

@media (min-width: 1024px) {
  body.home .c-header {
    height: 8rem;
  }
}

.c-header__inner {
  background-color: #fff;
}

.js-header-fixed .c-header__inner {
  position: fixed;
  z-index: 8;
  top: 0;
  left: 0;
  width: 100%;
}

.c-header__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

@media (min-width: 1024px) {
  .c-header__content {
    padding-top: 3rem;
    padding-bottom: 2.8rem;
  }
}

.c-header-nav {
  display: none;
}

@media (min-width: 1024px) {
  .c-header-nav {
    flex: 1;
    display: flex;
    list-style: none;
    margin: 0;
    font-family: National, serif;
    font-weight: 400;
    font-size: 1.5rem;
    letter-spacing: 1.15px;
    text-transform: uppercase;
  }
}

.c-header-nav.c-header-nav--right {
  justify-content: flex-end;
}

@media (min-width: 1024px) {
  .c-header-nav__li {
    margin-bottom: 0;
    margin-right: 1.5rem;
  }
  .c-header-nav__li:last-child {
    margin-right: 0;
  }
}

@media (min-width: 1280px) {
  .c-header-nav__li {
    margin-right: 3rem;
  }
}

.c-header-nav__a {
  text-decoration: none;
  color: #172724;
}

.c-header-logo {
  flex: 0 0 134px;
}

@media (min-width: 1280px) {
  .c-header-logo {
    flex: 0 0 176px;
  }
}

.c-header-logo__image {
  width: 100%;
}

.c-header-space-keeper {
  display: block;
  flex: 1;
}

@media (min-width: 1024px) {
  .c-header-space-keeper {
    display: none;
  }
}

.c-header-hamburger {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

@media (min-width: 1024px) {
  .c-header-hamburger {
    display: none;
  }
}

.c-header-hamburger__shape {
  display: block;
  width: 3rem;
  cursor: pointer;
  padding: 0.3rem 0 0.3rem 0.5rem;
}

.c-header-hamburger__shape-line {
  display: block;
  width: 2rem;
  height: 0.2rem;
  background: #000;
}

.c-header-hamburger__shape-line.c-header-hamburger__shape-line--center {
  margin-top: 4px;
  margin-bottom: 4px;
}

.c-hero-with-shape {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-top: 60px;
  min-height: calc(100vh - 60px);
}

@media (min-width: 1024px) {
  .c-hero-with-shape {
    margin-top: 83px;
    min-height: calc(100vh - 83px);
  }
}

.c-hero-with-shape--decorated {
  background: linear-gradient(to top, rgba(0, 0, 0, 0.65), transparent 15%);
}

.c-hero-with-shape__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover; object-position: center;';
}

.c-hero-with-shape__content {
  position: relative;
  z-index: 1;
  width: 78rem;
  max-width: 100%;
  color: #fff;
  text-align: center;
}

.c-hero-with-shape__quote {
  font-size: 1.8rem;
  line-height: 2.4rem;
  letter-spacing: 0.3px;
  font-weight: 200;
  margin-bottom: 0;
}

@media (min-width: 480px) {
  .c-hero-with-shape__quote {
    font-size: 2.2rem;
    line-height: 3rem;
  }
}

@media (min-width: 768px) {
  .c-hero-with-shape__quote {
    font-size: 2.7rem;
    line-height: 4.2rem;
    letter-spacing: 0.5px;
  }
}

.c-hero-with-shape__author {
  display: block;
  font-family: National, serif;
  font-weight: 300;
  font-size: 1.3rem;
  letter-spacing: 1.15px;
  text-transform: uppercase;
  margin-top: 3rem;
}

@media (min-width: 480px) {
  .c-hero-with-shape__author {
    font-size: 1.5rem;
    margin-top: 6.5rem;
  }
}

.c-hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
  min-height: calc(100vh - 60px);
}

@media (min-width: 1024px) {
  .c-hero {
    margin-top: 83px;
    min-height: calc(100vh - 83px);
  }
}

.c-hero--decorated {
  background: linear-gradient(to top, rgba(0, 0, 0, 0.65), transparent 15%);
}

.c-hero--no-header {
  margin-top: 0;
  min-height: 100vh;
}

.c-hero__intro {
  max-width: 76.9rem;
  margin-bottom: 6rem;
  font-family: "National";
  font-weight: 300;
  font-size: 1.8rem;
  text-align: center;
}

@media (min-width: 768px) {
  .c-hero__intro {
    font-family: "Canela";
    font-size: 2.7rem;
  }
}

.c-hero__intro--light {
  color: #fff;
}

.c-hero__intro--dark {
  color: #172724;
}

.c-hero__link {
  color: inherit;
  margin-bottom: 0.2rem;
}

.c-hero__heading {
  font-size: 3.9rem;
  font-weight: 200;
  text-align: center;
}

@media (min-width: 768px) {
  .c-hero__heading {
    font-size: 5.7rem;
  }
}

.c-hero__heading--light {
  color: #fff;
}

.c-hero__heading--dark {
  color: #172724;
}

.c-hero__decoration {
  margin: auto;
  font-size: 1.5rem;
  font-weight: 500;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.15px;
}

.c-hero__decoration::after {
  content: '';
  display: block;
  width: 1px;
  height: 5rem;
  margin: 1rem auto auto;
  background-color: #fff;
}

@media (min-width: 1024px) {
  .c-hero__decoration::after {
    height: 7.4rem;
  }
}

.c-hero__top {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10rem;
}

@media (min-width: 1024px) {
  .c-hero__top {
    padding-top: 22rem;
    padding-bottom: 10rem;
  }
}

.c-home-initial {
  height: 100vh;
  padding-top: 7.5rem;
}

.c-home-initial-wrapper {
  height: 100%;
}

.c-home-initial__logo-wrapper {
  text-align: center;
  height: 6%;
}

.c-home-initial__gap {
  height: 10%;
}

@media (min-width: 480px) {
  .c-home-initial__gap {
    height: 9%;
  }
}

.c-home-initial__image-wrapper {
  text-align: center;
  height: 85%;
  padding-bottom: 12rem;
}

@media (min-width: 1024px) {
  .c-home-initial__image-wrapper {
    padding-bottom: 14rem;
  }
}

.c-home-initial__logo {
  max-height: 100%;
}

.c-home-initial__image {
  max-height: 100%;
}

.c-image-section__container {
  width: 100%;
}

.c-image-section__image {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  font-family: 'object-fit: cover; object-position: center;';
}

.c-living-carousel {
  margin-top: 5.5rem;
  margin-bottom: 5.5rem;
}

@media (min-width: 768px) {
  .c-living-carousel {
    margin-top: 11.5rem;
    margin-bottom: 14rem;
  }
}

.c-living-carousel__top {
  text-align: left;
  margin-bottom: 3rem;
}

@media (min-width: 480px) {
  .c-living-carousel__top {
    margin-bottom: 6rem;
  }
}

@media (min-width: 768px) {
  .c-living-carousel__top {
    text-align: center;
    margin-bottom: 12rem;
  }
}

.c-living-carousel__title {
  font-size: 1.8rem;
  letter-spacing: 0.3px;
  line-height: 2.4rem;
  font-family: Canela, serif;
  color: #172724;
  font-weight: 200;
  margin-bottom: 0;
}

@media (min-width: 480px) {
  .c-living-carousel__title {
    font-size: 2.3rem;
    line-height: 3rem;
  }
}

@media (min-width: 768px) {
  .c-living-carousel__title {
    font-size: 2.7rem;
    letter-spacing: 0.5px;
    line-height: 4.2rem;
  }
}

.c-living-carousel__line {
  display: none;
}

@media (min-width: 768px) {
  .c-living-carousel__line {
    display: inline-block;
    height: 33px;
    width: 1px;
    background-color: #979797;
    margin-top: 20px;
    margin-bottom: 35px;
  }
}

.c-living-carousel-nav {
  display: none;
}

@media (min-width: 768px) {
  .c-living-carousel-nav {
    display: flex;
    justify-content: center;
    list-style: none;
    margin: 0;
    text-transform: uppercase;
  }
}

.c-living-carousel-nav__item {
  flex: 0 0 8.5rem;
  margin: 0 0.7rem;
  cursor: pointer;
}

.c-living-carousel-nav__dot {
  display: block;
  border: 1px solid #172724;
  border-radius: 50%;
  width: 1.7rem;
  height: 1.7rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  transition: background-color 0.3s;
}

.js-active .c-living-carousel-nav__dot {
  background-color: #172724;
}

.c-living-carousel-nav__title {
  font-family: National, serif;
  font-weight: 400;
  font-size: 1.5rem;
  color: #172724;
  letter-spacing: 1.15px;
}

.c-living-slider {
  list-style: none;
  margin: 0;
}

.c-living-slider .flickity-page-dots {
  bottom: -30px;
}

.c-living-slider .flickity-page-dots .dot {
  background: transparent;
  border: 1px solid #8c8c8c;
  opacity: 1;
}

.c-living-slider .flickity-page-dots .dot.is-selected {
  background: #ccc;
}

@media (min-width: 480px) {
  .c-living-slider .flickity-page-dots {
    display: none;
  }
}

.c-living-slider .flickity-prev-next-button.previous {
  display: none;
}

@media (min-width: 768px) {
  .c-living-slider .flickity-prev-next-button.previous {
    display: block;
    left: 2rem;
  }
}

@media (min-width: 1024px) {
  .c-living-slider .flickity-prev-next-button.previous {
    left: 6rem;
  }
}

.c-living-slider .flickity-prev-next-button.next {
  display: none;
}

@media (min-width: 768px) {
  .c-living-slider .flickity-prev-next-button.next {
    display: block;
    right: 2rem;
  }
}

@media (min-width: 1024px) {
  .c-living-slider .flickity-prev-next-button.next {
    right: 6rem;
  }
}

.c-living-slide {
  width: 85%;
  padding: 1.5rem 1rem;
  border: 1px solid #ccc;
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}

@media (min-width: 480px) {
  .c-living-slide {
    display: block;
    padding: 2rem 2.5rem;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    width: 75%;
  }
}

@media (min-width: 768px) {
  .c-living-slide {
    padding: 3rem 3.5rem;
  }
}

@media (min-width: 1024px) {
  .c-living-slide {
    width: 66.66%;
    margin-left: 30px;
    margin-right: 30px;
  }
}

.c-living-slide__image {
  width: 100%;
}

.c-living-slide__bottom {
  display: block;
  font-family: National, serif;
  color: #172724;
  margin-top: 2rem;
}

@media (min-width: 480px) {
  .c-living-slide__bottom {
    margin-top: 3rem;
  }
}

@media (min-width: 768px) {
  .c-living-slide__bottom {
    display: flex;
  }
}

.c-living-slide__content {
  flex: 0 0 auto;
}

@media (min-width: 768px) {
  .c-living-slide__content {
    width: 50%;
    padding-right: 6rem;
  }
}

.c-living-slide__title {
  font-weight: 400;
  letter-spacing: 1.15px;
  text-transform: uppercase;
  margin-bottom: 0;
}

.c-living-slide__title.c-living-slide__title--mobile {
  display: block;
  margin-bottom: 1rem;
  font-size: 13px;
}

@media (min-width: 480px) {
  .c-living-slide__title.c-living-slide__title--mobile {
    display: none;
  }
}

.c-living-slide__title.c-living-slide__title--desktop {
  display: none;
}

@media (min-width: 480px) {
  .c-living-slide__title.c-living-slide__title--desktop {
    display: block;
    font-size: 1.5rem;
  }
}

.c-living-slide__description {
  font-size: 1.3rem;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 1.9rem;
  text-align: center;
}

@media (min-width: 480px) {
  .c-living-slide__description {
    text-align: left;
    font-size: 1.5rem;
    line-height: 2.1rem;
  }
}

.c-living-slide__features {
  flex: 0 0 auto;
  list-style: none;
  margin: 3.6rem 0 0 0;
}

@media (min-width: 768px) {
  .c-living-slide__features {
    width: 50%;
    padding-left: 6rem;
  }
}

.c-living-slide__feature {
  text-align: center;
  margin-bottom: 3.3rem;
}

.c-living-slide__feature:last-child {
  margin-bottom: 2.3rem;
}

@media (min-width: 480px) {
  .c-living-slide__feature {
    display: flex;
    text-align: left;
    margin: 0;
  }
}

.c-living-slide__feature-name {
  flex: 0 0 10.5rem;
  font-weight: 400;
  font-size: 1.3rem;
  letter-spacing: 1px;
  line-height: 1.6rem;
  text-transform: uppercase;
  display: block;
  margin-bottom: 5px;
}

@media (min-width: 480px) {
  .c-living-slide__feature-name {
    font-size: 1.2rem;
    line-height: 1.7rem;
    margin-bottom: 0;
  }
}

.c-living-slide__feature-content {
  flex: 1 1 auto;
  font-weight: 300;
  font-size: 1.5rem;
  line-height: 1.6rem;
  letter-spacing: 0;
  display: block;
}

@media (min-width: 480px) {
  .c-living-slide__feature-content {
    font-size: 1.2rem;
  }
}

/* ==========================================================================
   #MAIN NAV
   ========================================================================== */
.c-main-nav {
  display: flex;
  margin: 0 0 2em 0;
}

.c-main-nav__item {
  font-weight: bold;
  list-style: none;
  margin: 0 1em 0 0;
  padding: 0;
}

.c-main-nav__item:hover .c-main-nav__dropdown {
  display: flex;
}

.c-main-nav__link {
  border-bottom: 1px solid #ccc;
  display: block;
  padding: 0.5em;
  text-decoration: none;
}

.current-menu-item .c-main-nav__link {
  border-bottom-color: #000;
}

.c-main-nav__dropdown {
  display: none;
  flex-direction: column;
  margin: 0;
  position: absolute;
}

.c-main-nav__subitem {
  list-style: none;
  margin: 0;
}

.c-main-nav__sublink {
  background: #fff;
  border-bottom: 1px solid #ccc;
  display: block;
  padding: 0.5em;
  text-decoration: none;
}

.current-menu-item .c-main-nav__sublink {
  border-bottom-color: #000;
}

.c-map {
  background-color: #f3f1eb;
}

.c-map__inner {
  padding-top: 5rem;
}

@media (min-width: 768px) {
  .c-map__inner {
    padding-top: 10rem;
  }
}

@media (min-width: 1280px) {
  .c-map__inner {
    padding-top: 18rem;
  }
}

.c-map__top {
  margin-bottom: 7rem;
}

@media (min-width: 1280px) {
  .c-map__top {
    margin-bottom: 19.5rem;
  }
}

.c-map__description {
  font-family: Canela, serif;
  font-size: 1.8rem;
  line-height: 2.4rem;
  letter-spacing: 0.3px;
  width: 770px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

@media (min-width: 768px) {
  .c-map__description {
    font-size: 2.7rem;
    line-height: 4.2rem;
    letter-spacing: 0.5px;
  }
}

.c-map__bottom {
  position: relative;
}

.c-map__image {
  width: 100%;
}

.c-map-points {
  display: none;
}

@media (min-width: 768px) {
  .c-map-points {
    display: block;
    margin: 0;
    list-style: none;
  }
}

.c-map-point {
  position: absolute;
  margin: 0;
}

.c-map-point__tab {
  width: 260px;
  background: #f3f1eb;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 1.5rem;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
  box-shadow: 10px 10px 20px -5px rgba(0, 0, 0, 0.5);
}

.js-active .c-map-point__tab {
  opacity: 1;
  visibility: visible;
}

.c-map-point__tab::before, .c-map-point__tab:after {
  content: '';
  position: absolute;
  top: calc(100% - 1px);
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

.c-map-point__tab::before {
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-top: 7px solid #ccc;
}

.c-map-point__tab::after {
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  border-top: 6px solid #f3f1eb;
}

.c-map-point__image {
  width: 100%;
  height: 150px;
  object-fit: cover;
  font-family: 'object-fit: cover; object-position: center;';
}

.c-map-point__content {
  padding: 1rem 1.5rem;
  text-align: center;
  font-family: National, serif;
  font-size: 1.3rem;
  line-height: 1.6rem;
  border: 1px solid #ccc;
}

.c-map-point__title {
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 400;
}

.c-map-point__description {
  margin-top: 5px;
  font-weight: 300;
}

.c-map-point__dot {
  background: #a36631;
  border: 2px solid #fff;
  display: block;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
  width: 20px;
  height: 20px;
  transform: scale(1);
  transition: transform 0.3s;
}

@media (min-width: 1280px) {
  .c-map-point__dot {
    width: 26px;
    height: 26px;
  }
}

.c-map-point__dot::after {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  width: 20px;
  height: 20px;
  border: 2px solid white;
  border-radius: 50%;
  opacity: 0;
  will-change: transform;
  animation: dotPulse 3s infinite;
}

@media (min-width: 1280px) {
  .c-map-point__dot::after {
    width: 26px;
    height: 26px;
  }
}

.c-map-point__dot:hover {
  transform: scale(1.2);
}

.c-palette {
  padding-top: 4.5rem;
  padding-bottom: 4.5rem;
  transition-property: color, background-color;
  transition-duration: 0.3s;
}

@media (min-width: 768px) {
  .c-palette {
    padding-top: 11rem;
    padding-bottom: 7.5rem;
  }
}

.c-palette.js-light {
  color: #172724;
  background-color: #f3f1eb;
}

.c-palette.js-dark {
  color: #f3f1eb;
  background-color: #7e7a73;
}

.c-palette__top {
  text-align: center;
  margin-bottom: 3.5rem;
  width: 740px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 480px) {
  .c-palette__top {
    margin-bottom: 6rem;
  }
}

@media (min-width: 768px) {
  .c-palette__top {
    margin-bottom: 12rem;
  }
}

.c-palette__title {
  font-size: 1.8rem;
  letter-spacing: 0.3px;
  line-height: 2.4rem;
  font-family: Canela, serif;
  font-weight: 200;
  margin-bottom: 0;
}

@media (min-width: 480px) {
  .c-palette__title {
    font-size: 2.3rem;
    line-height: 3rem;
  }
}

@media (min-width: 768px) {
  .c-palette__title {
    font-size: 2.7rem;
    letter-spacing: 0.5px;
    line-height: 4.2rem;
  }
}

.c-palette-switcher {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4.5rem;
  cursor: pointer;
}

.c-palette-switcher__label {
  text-transform: uppercase;
  font-size: 1.3rem;
  line-height: 1.6rem;
  letter-spacing: 1px;
  font-weight: 400;
}

.c-palette-switch {
  margin-left: 3rem;
  margin-right: 3rem;
  position: relative;
}

.c-palette-switch__line {
  display: block;
  width: 55px;
  border-top: 1px solid #ccc;
}

.c-palette-switch__dot {
  position: absolute;
  right: 100%;
  top: 50%;
  transform: translateY(-50%);
  display: block;
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 50%;
  transition-property: right, background-color;
  transition-duration: 0.3s;
}

.js-light .c-palette-switch__dot {
  right: 100%;
  border: 1px solid #172724;
  background-color: #f3f1eb;
}

.js-dark .c-palette-switch__dot {
  right: -16px;
  border: 1px solid #f3f1eb;
  background-color: #7e7a73;
}

.c-palette__shades {
  position: relative;
}

.c-palette__bottom {
  transition-property: opacity, visibility;
  transition-duration: 0.3s;
}

.js-dark .c-palette__bottom.c-palette__bottom--light {
  opacity: 0;
  visibility: hidden;
}

.js-light .c-palette__bottom.c-palette__bottom--light {
  opacity: 1;
  visibility: visible;
}

.c-palette__bottom.c-palette__bottom--dark {
  color: #f3f1eb;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.js-dark .c-palette__bottom.c-palette__bottom--dark {
  opacity: 1;
  visibility: visible;
}

.js-light .c-palette__bottom.c-palette__bottom--dark {
  opacity: 0;
  visibility: hidden;
}

.c-palette-overview {
  margin-bottom: 3rem;
}

@media (min-width: 480px) {
  .c-palette-overview {
    margin-bottom: 6rem;
  }
}

@media (min-width: 768px) {
  .c-palette-overview {
    margin-bottom: 10rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.c-palette-overview__image-wrapper {
  text-align: center;
}

@media (min-width: 768px) {
  .c-palette-overview__image-wrapper {
    flex: 0 1 47rem;
    text-align: left;
  }
}

.c-palette-overview__image {
  width: 100%;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) {
  .c-palette-overview__image {
    max-width: 100%;
  }
}

.c-palette-overview__content {
  text-align: center;
  font-family: National, serif;
  margin-top: 3.5rem;
}

@media (min-width: 768px) {
  .c-palette-overview__content {
    margin-left: 7rem;
    flex: 0 1 28rem;
    margin-top: 0;
  }
}

@media (min-width: 1024px) {
  .c-palette-overview__content {
    margin-left: 15rem;
  }
}

.c-palette-overview__title {
  font-size: 1.3rem;
  letter-spacing: 1.15px;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 2rem;
}

@media (min-width: 480px) {
  .c-palette-overview__title {
    margin-bottom: 5.5rem;
    font-size: 1.5rem;
  }
}

.c-palette-overview__description {
  font-weight: 300;
  font-size: 1.3rem;
  line-height: 1.9rem;
}

@media (min-width: 480px) {
  .c-palette-overview__description {
    font-size: 1.5rem;
    line-height: 2.1rem;
  }
}

.c-palette-slider {
  list-style: none;
  margin: 0;
}

.c-palette-slider .flickity-page-dots {
  bottom: -30px;
}

.c-palette-slider .flickity-page-dots .dot {
  background: transparent;
  border: 1px solid #8c8c8c;
  opacity: 1;
}

.c-palette-slider .flickity-page-dots .dot.is-selected {
  background: #ccc;
}

@media (min-width: 480px) {
  .c-palette-slider .flickity-page-dots {
    display: none;
  }
}

.c-palette-slider .flickity-prev-next-button.previous {
  display: none;
}

@media (min-width: 768px) {
  .c-palette-slider .flickity-prev-next-button.previous {
    display: block;
    left: 2rem;
  }
}

@media (min-width: 1024px) {
  .c-palette-slider .flickity-prev-next-button.previous {
    left: 6rem;
  }
}

.c-palette-slider .flickity-prev-next-button.next {
  display: none;
}

@media (min-width: 768px) {
  .c-palette-slider .flickity-prev-next-button.next {
    display: block;
    right: 2rem;
  }
}

@media (min-width: 1024px) {
  .c-palette-slider .flickity-prev-next-button.next {
    right: 6rem;
  }
}

.c-palette-slide {
  width: 85%;
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}

@media (min-width: 480px) {
  .c-palette-slide {
    display: block;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    width: 75%;
  }
}

@media (min-width: 1024px) {
  .c-palette-slide {
    width: 66.66%;
    margin-left: 3rem;
    margin-right: 3rem;
  }
}

.c-palette-slide__image {
  width: 100%;
}

.c-palette-slide__bottom {
  display: block;
  font-family: National, serif;
  margin-top: 2rem;
}

@media (min-width: 480px) {
  .c-palette-slide__bottom {
    margin-top: 3rem;
  }
}

@media (min-width: 768px) {
  .c-palette-slide__bottom {
    display: flex;
  }
}

.c-palette-slide__content {
  flex: 0 0 50%;
}

@media (min-width: 768px) {
  .c-palette-slide__content {
    padding-right: 6rem;
  }
}

.c-palette-slide__title {
  font-weight: 400;
  letter-spacing: 1.15px;
  text-transform: uppercase;
  font-size: 1.3rem;
  margin-bottom: 0;
}

@media (min-width: 480px) {
  .c-palette-slide__title {
    margin-bottom: 0;
    font-size: 1.5rem;
  }
}

.c-palette-slide__description {
  font-weight: 300;
  letter-spacing: 0;
  opacity: 0.6;
  font-size: 1.3rem;
  line-height: 1.9rem;
}

/* ==========================================================================
   #POST
   Includes styles for default WP images classes
   ========================================================================== */
.c-post {
  margin-bottom: 2em;
}

.c-post:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

.alignnone {
  margin: 1em 1em 1em 0;
}

.aligncenter {
  margin: 1em auto;
}

.alignright {
  float: right;
  margin: 1em 0 1em 1em;
}

.alignleft {
  float: left;
  margin: 1em 1em 1em 0;
}

.wp-caption {
  text-align: center;
}

.wp-caption-text {
  font-style: italic;
  margin-top: 0.5em;
}

.c-preloader {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 11;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  will-change: opacity, visibility;
  animation: fadeOutPreloader 2s forwards;
}

.c-preloader.active {
  animation: fadeInPreloader 1s forwards;
}

.c-push-menu-overlay {
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  background: rgba(0, 0, 0, 0.5);
  transition-property: opacity, visibility;
  transition-duration: 0.3s;
}

.js-push-menu--active .c-push-menu-overlay {
  opacity: 1;
  visibility: visible;
}

.c-push-menu {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateX(-100%);
  will-change: transform;
  transition: transform 0.3s;
  background: #172724;
}

@media (min-width: 480px) {
  .c-push-menu {
    width: 500px;
    max-width: 90%;
  }
}

.js-push-menu--active .c-push-menu {
  transform: translateX(0);
}

.c-push-menu__inner {
  padding: 4rem 3.5rem;
}

.c-push-menu__content {
  position: relative;
}

.c-push-menu__close {
  display: block;
  right: 0;
  top: 0;
  width: 32px;
  height: 32px;
  position: absolute;
  cursor: pointer;
}

.c-push-menu__close::before, .c-push-menu__close::after {
  position: absolute;
  left: 15px;
  content: '';
  height: 33px;
  width: 1px;
  background-color: #f3f1eb;
}

.c-push-menu__close::before {
  transform: rotate(45deg);
}

.c-push-menu__close::after {
  transform: rotate(-45deg);
}

.c-push-nav {
  padding-top: 7.5rem;
  list-style: none;
  font-family: Canela-Light;
  font-size: 2.6rem;
  letter-spacing: 0.6px;
  text-align: center;
  line-height: 5.8rem;
}

.c-push-nav__li {
  margin-bottom: 0;
}

.c-push-nav__a {
  color: #f3f1eb;
  text-decoration: none;
}

.c-quote {
  width: 69.5rem;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  padding-top: 5rem;
  padding-bottom: 5rem;
}

@media (min-width: 480px) {
  .c-quote {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
}

@media (min-width: 768px) {
  .c-quote {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }
}

@media (min-width: 1024px) {
  .c-quote {
    padding-top: 23.5rem;
    padding-bottom: 23.5rem;
  }
}

.c-quote.c-quote--between {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

@media (min-width: 768px) {
  .c-quote.c-quote--between {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

.c-quote__description {
  font-family: Canela, serif;
  font-weight: 200;
  font-size: 1.8rem;
  line-height: 2.4rem;
}

@media (min-width: 480px) {
  .c-quote__description {
    font-size: 2rem;
    line-height: 3rem;
  }
}

@media (min-width: 768px) {
  .c-quote__description {
    font-size: 2.7rem;
    line-height: 4.2rem;
  }
}

.c-responsive-image__inner {
  position: relative;
  height: 0;
  width: 100%;
}

.c-responsive-image--full .c-responsive-image__inner {
  min-height: 100%;
}

.c-responsive-image__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-family: 'object-fit: cover; object-position: center;';
  object-fit: cover;
}

.c-reviews {
  padding-top: 6.7rem;
  padding-bottom: 6.7rem;
}

@media (min-width: 480px) {
  .c-reviews {
    padding-bottom: 8rem;
  }
}

@media (min-width: 768px) {
  .c-reviews {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }
}

@media (min-width: 1024px) {
  .c-reviews {
    padding-top: 20rem;
    padding-bottom: 20rem;
  }
}

.c-reviews__carousel {
  width: 154rem;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.c-reviews__carousel .flickity-button {
  display: none;
}

@media (min-width: 768px) {
  .c-reviews__carousel .flickity-button {
    display: block;
    height: 35px;
    width: 35px;
  }
}

@media (min-width: 1024px) {
  .c-reviews__carousel .flickity-button {
    height: 50px;
    width: 50px;
  }
}

.c-reviews__carousel .flickity-page-dots {
  bottom: -50px;
}

@media (min-width: 768px) {
  .c-reviews__carousel .flickity-page-dots {
    display: none;
  }
}

.c-reviews__carousel .flickity-page-dots .dot {
  border: 1px solid #979797;
  background: transparent;
  opacity: 1;
  width: 1rem;
  height: 1rem;
  margin: 0 5px;
}

.c-reviews__carousel .flickity-page-dots .dot.is-selected {
  background: #d8d8d8;
}

.c-review {
  width: 100%;
}

@media (min-width: 768px) {
  .c-review {
    padding-left: 6rem;
    padding-right: 6rem;
  }
}

@media (min-width: 1024px) {
  .c-review {
    padding-left: 14rem;
    padding-right: 14rem;
  }
}

@media (min-width: 768px) {
  .c-review__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.c-review__left {
  text-align: center;
  margin-bottom: 45px;
}

@media (min-width: 768px) {
  .c-review__left {
    width: 30%;
    margin-bottom: 0;
    text-align: left;
    flex: 1 1 auto;
    margin-right: 1.5rem;
  }
}

@media (min-width: 1280px) {
  .c-review__left {
    width: 47.61905%;
    flex: 0 1 auto;
  }
}

.c-review__image {
  width: 100%;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) {
  .c-review__image {
    max-width: 590px;
  }
}

.c-review__right {
  text-align: center;
}

@media (min-width: 768px) {
  .c-review__right {
    flex: 1 1 auto;
    max-width: 48rem;
    margin-left: 1.5rem;
  }
}

.c-review__content {
  color: #fff;
  text-align: center;
}

@media (min-width: 768px) {
  .c-review__content {
    max-width: 473px;
    margin-left: auto;
  }
}

.c-review__quote {
  font-family: Canela;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.3px;
}

@media (min-width: 768px) {
  .c-review__quote {
    font-size: 23px;
    line-height: 30px;
    letter-spacing: 0.4px;
  }
}

@media (min-width: 1024px) {
  .c-review__quote {
    font-size: 27px;
    line-height: 42px;
    letter-spacing: 0.5px;
  }
}

.c-review__author {
  display: block;
  letter-spacing: 1.15px;
  font-size: 15px;
  line-height: 19px;
  text-transform: uppercase;
  font-weight: 400;
  margin-top: 25px;
}

.c-screen-image {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

.c-screen-image.c-screen-image--darker {
  background: #000;
}

.c-screen-image.c-screen-image--aztec {
  background: #000;
}

.c-screen-image__wrapper {
  width: 100%;
  height: 100%;
}

.c-screen-image__image {
  position: absolute;
  max-width: none;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover; object-position: center;';
  left: 0;
  top: 0;
  transform: none;
}

.c-screen-image--darker .c-screen-image__image {
  opacity: 0.7;
}

.c-split-section-h-wrapper {
  width: 1360px;
  max-width: 100%;
  align-items: initial;
  padding-top: 5rem;
  padding-bottom: 5rem;
}

@media (min-width: 768px) {
  .c-split-section-h-wrapper {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
}

@media (min-width: 1024px) {
  .c-split-section-h-wrapper {
    padding-top: 20rem;
    padding-bottom: 20rem;
  }
}

.c-split-section-h-wrapper.c-split-section-h-wrapper--no-bottom-padding {
  padding-bottom: 0;
}

.c-split-section-h {
  display: block;
}

@media (min-width: 768px) {
  .c-split-section-h {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

@media (min-width: 768px) {
  .c-split-section-h--desktop-reversed {
    flex-direction: row-reverse;
  }
}

@media (max-width: 767.98px) {
  .c-split-section-h--mobile-reversed {
    display: flex;
    flex-direction: column-reverse;
  }
}

.c-split-section-h-image {
  width: 100%;
  max-width: 60rem;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) {
  .c-split-section-h-image {
    max-width: 50vw;
    margin-left: 0;
    margin-right: 0;
    flex: 1 1 auto;
  }
}

@media (min-width: 1024px) {
  .c-split-section-h-image {
    max-width: 82rem;
  }
}

@media (max-width: 479.98px) {
  .c-split-section-h-image.c-split-section-h-image--full-mobile {
    width: auto;
    margin-left: -2rem;
    margin-right: -2rem;
  }
}

.c-split-section-h-image__image {
  width: 100%;
  height: auto;
}

.c-split-section-h-content {
  max-width: 50rem;
  margin: 0 auto 4rem;
  text-align: center;
  color: #172724;
}

@media (min-width: 768px) {
  .c-split-section-h-content {
    padding-left: 0;
    padding-right: 2rem;
    margin-bottom: 0;
    margin-left: 0;
    max-width: 38rem;
    flex: 1 1 auto;
  }
}

@media (max-width: 767.98px) {
  .c-split-section-h--mobile-reversed .c-split-section-h-content {
    margin-bottom: 0;
    margin-top: 4rem;
  }
}

@media (min-width: 768px) {
  .c-split-section-h--desktop-reversed .c-split-section-h-content {
    padding-left: 2rem;
    padding-right: 0;
    margin-left: auto;
    margin-right: 0;
  }
}

.c-split-section-h-content__heading {
  font-family: Canela, serif;
  font-size: 1.8rem;
  letter-spacing: 0.3px;
  line-height: 2.4rem;
  margin-bottom: 40px;
}

@media (min-width: 768px) {
  .c-split-section-h-content__heading {
    font-size: 2.7rem;
    letter-spacing: 0.5px;
    line-height: 4.2rem;
  }
}

.c-split-section-h-content__heading--is-subheading {
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  .c-split-section-h-content__heading--is-subheading {
    margin-bottom: 5px;
  }
}

.c-split-section-h-content__subheading {
  font-family: National, serif;
  font-weight: 300;
  font-style: italic;
  font-size: 1.4rem;
  letter-spacing: 0.35px;
  line-height: 1.9rem;
  margin-bottom: 15px;
}

@media (min-width: 768px) {
  .c-split-section-h-content__subheading {
    font-size: 1.6rem;
    letter-spacing: 0.4px;
    margin-bottom: 50px;
  }
}

.c-split-section-h-content__copies {
  text-align: center;
  font-family: National, serif;
  font-weight: 300;
  font-size: 1.3rem;
  line-height: 1.9rem;
}

@media (min-width: 768px) {
  .c-split-section-h-content__copies {
    font-size: 1.5rem;
    line-height: 2.1rem;
  }
}

.c-split-section-h-content__copies p {
  margin-bottom: 1.5rem;
}

.c-split-section-h-content__copies p:last-child {
  margin-bottom: 0;
}

.c-split-section-h-content__link {
  font-family: National, serif;
  font-size: 1.3rem;
  letter-spacing: 1px;
  color: #172724;
  text-decoration: none;
}

.c-split-section-h-content__link::after {
  background-color: #979797;
}

.c-split-section-v-wrapper {
  width: 1360px;
  max-width: 100%;
  align-items: initial;
  padding-top: 5rem;
  padding-bottom: 5rem;
}

@media (min-width: 768px) {
  .c-split-section-v-wrapper {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
}

@media (min-width: 1024px) {
  .c-split-section-v-wrapper {
    padding-top: 20rem;
    padding-bottom: 20rem;
  }
}

.c-split-section-v-wrapper.c-split-section-v-wrapper--no-bottom-padding {
  padding-bottom: 0;
}

.c-split-section-v {
  display: block;
}

@media (min-width: 768px) {
  .c-split-section-v {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

@media (min-width: 768px) {
  .c-split-section-v--desktop-reversed {
    flex-direction: row-reverse;
  }
}

@media (max-width: 767.98px) {
  .c-split-section-v--mobile-reversed {
    display: flex;
    flex-direction: column-reverse;
  }
}

.c-split-section-v-image {
  width: 100%;
  max-width: 40rem;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) {
  .c-split-section-v-image {
    max-width: none;
    margin-left: 0;
    margin-right: 0;
    flex: 1 1 auto;
    width: 41vw;
  }
}

@media (min-width: 1024px) {
  .c-split-section-v-image {
    flex: 0 0 auto;
    width: 60rem;
  }
}

.c-split-section-v-image__image {
  width: 100%;
  height: auto;
}

.c-split-section-v-content {
  max-width: 50rem;
  margin: 0 auto 4rem;
  text-align: center;
  color: #172724;
}

@media (min-width: 768px) {
  .c-split-section-v-content {
    padding-left: 0;
    padding-right: 2rem;
    max-width: none;
    width: 44vw;
    margin-bottom: 0;
    margin-left: 0;
    flex: 1 1 auto;
  }
}

@media (min-width: 1024px) {
  .c-split-section-v-content {
    flex: 0 0 auto;
    width: 48rem;
  }
}

@media (max-width: 767.98px) {
  .c-split-section-v--mobile-reversed .c-split-section-v-content {
    margin-top: 4rem;
    margin-bottom: 0;
  }
}

@media (min-width: 768px) {
  .c-split-section-v--desktop-reversed .c-split-section-v-content {
    padding-left: 2rem;
    padding-right: 0;
    margin-left: auto;
    margin-right: 0;
  }
}

.c-split-section-v-content__heading {
  font-family: Canela, serif;
  font-size: 1.8rem;
  letter-spacing: 0.3px;
  line-height: 2.4rem;
  margin-bottom: 40px;
}

@media (min-width: 768px) {
  .c-split-section-v-content__heading {
    font-size: 2.7rem;
    letter-spacing: 0.5px;
    line-height: 4.2rem;
  }
}

.c-split-section-v-content__heading--is-subheading {
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  .c-split-section-v-content__heading--is-subheading {
    margin-bottom: 5px;
  }
}

.c-split-section-v-content__subheading {
  font-family: National, serif;
  font-weight: 300;
  font-style: italic;
  font-size: 1.4rem;
  letter-spacing: 0.35px;
  line-height: 1.9rem;
  margin-bottom: 15px;
}

@media (min-width: 768px) {
  .c-split-section-v-content__subheading {
    font-size: 1.6rem;
    letter-spacing: 0.4px;
    margin-bottom: 50px;
  }
}

.c-split-section-v-content__copies {
  margin-bottom: 3rem;
  text-align: center;
  font-family: National, serif;
  font-weight: 300;
  font-size: 1.3rem;
  line-height: 1.9rem;
}

@media (min-width: 768px) {
  .c-split-section-v-content__copies {
    font-size: 1.5rem;
    line-height: 2.1rem;
  }
}

.c-split-section-v-content__copies p {
  margin-bottom: 1.5rem;
}

.c-split-section-v-content__copies p:last-child {
  margin-bottom: 0;
}

.c-split-section-v-content__link {
  font-family: National, serif;
  font-size: 1.3rem;
  letter-spacing: 1px;
  color: #172724;
  text-decoration: none;
}

.c-title-text {
  width: 460px;
  max-width: 100%;
  text-align: center;
  padding-top: 4.5rem;
  padding-bottom: 4rem;
}

@media (min-width: 768px) {
  .c-title-text {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }
}

@media (min-width: 1024px) {
  .c-title-text {
    padding-top: 20rem;
    padding-bottom: 20rem;
  }
}

.c-title-text__heading {
  text-transform: uppercase;
  font-size: 1.3rem;
  margin-bottom: 0;
  font-weight: 400;
}

@media (min-width: 768px) {
  .c-title-text__heading {
    font-size: 1.5rem;
  }
}

.c-title-text__line {
  display: inline-block;
  height: 33px;
  width: 1px;
  background-color: #979797;
  margin-top: 15px;
  margin-bottom: 15px;
}

.c-title-text__paragraphs {
  font-size: 1.3rem;
  font-weight: 300;
  font-family: National;
  font-style: normal;
}

.c-title-text__paragraphs p {
  margin-bottom: 1.5rem;
}

.c-title-text__paragraphs p:last-child {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .c-title-text__paragraphs {
    font-size: 1.5rem;
    line-height: 2.1rem;
  }
}
