@keyframes dotPulse {
  0% {
    transform: scale(1);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: scale(1.5);
  }
}

@keyframes fadeOutPreloader {
  0% {
    opacity: 1;
    visibility: visible;
  }

  20% {
    opacity: 1;
    visibility: visible;
  }

  100% {
    opacity: 0;
    visibility: hidden;
  }
}


@keyframes fadeInPreloader {
  0% {
    opacity: 0;
    visibility: hidden;
  }

  100% {
    opacity: 1;
    visibility: visible;
  }
}
