.c-entry-gate {
  position: relative;
  // width set for IE
  width: 100%;
}

.c-entry-gate__image {
  opacity: 0.7;
}

.c-entry-gate__btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
