.c-split-section-h-wrapper {
  width: 1360px;
  max-width: 100%;
  align-items: initial;
  padding-top: 5rem;
  padding-bottom: 5rem;

  @include bp(medium) {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  @include bp(large) {
    padding-top: 20rem;
    padding-bottom: 20rem;
  }

  &.c-split-section-h-wrapper--no-bottom-padding {
    padding-bottom: 0;
  }
}

.c-split-section-h {
  display: block;

  @include bp(medium) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &--desktop-reversed {
    @include bp(medium) {
      flex-direction: row-reverse;
    }
  }

  &--mobile-reversed {
    @include bp-down(medium) {
      display: flex;
      flex-direction: column-reverse;
    }
  }
}

.c-split-section-h-image {
  width: 100%;
  max-width: 60rem;
  margin-left: auto;
  margin-right: auto;

  @include bp(medium) {
    max-width: 50vw;
    margin-left: 0;
    margin-right: 0;
    flex: 1 1 auto;
  }

  @include bp(large) {
    max-width: 82rem;
  }

  &.c-split-section-h-image--full-mobile {
    @include bp-down(small) {
      width: auto;
      margin-left: -$size-padding;
      margin-right: -$size-padding;
    }
  }
}

.c-split-section-h-image__image {
  width: 100%;
  height: auto;
}

.c-split-section-h-content {
  max-width: 50rem;
  margin: 0 auto 4rem;
  text-align: center;
  color: $color-aztec;

  @include bp(medium) {
    padding-left: 0;
    padding-right: $size-padding;
    margin-bottom: 0;
    margin-left: 0;
    max-width: 38rem;
    flex: 1 1 auto;
  }

  .c-split-section-h--mobile-reversed & {
    @include bp-down(medium) {
      margin-bottom: 0;
      margin-top: 4rem;
    }
  }

  .c-split-section-h--desktop-reversed & {
    @include bp(medium) {
      padding-left: $size-padding;
      padding-right: 0;
      margin-left: auto;
      margin-right: 0;
    }
  }
}

.c-split-section-h-content__heading {
  font-family: Canela, serif;
  font-size: 1.8rem;
  letter-spacing: 0.3px;
  line-height: 2.4rem;
  margin-bottom: 40px;

  @include bp(medium) {
    font-size: 2.7rem;
    letter-spacing: 0.5px;
    line-height: 4.2rem;
  }

  &--is-subheading {
    margin-bottom: 10px;
    @include bp(medium) {
      margin-bottom: 5px;
    }
  }
}

.c-split-section-h-content__subheading {
  font-family: National, serif;
  font-weight: 300;
  font-style: italic;
  font-size: 1.4rem;
  letter-spacing: 0.35px;
  line-height: 1.9rem;
  margin-bottom: 15px;

  @include bp(medium) {
    font-size: 1.6rem;
    letter-spacing: 0.4px;
    margin-bottom: 50px;
  }
}

.c-split-section-h-content__copies {
  text-align: center;
  font-family: National, serif;
  font-weight: 300;
  font-size: 1.3rem;
  line-height: 1.9rem;

  @include bp(medium) {
    font-size: 1.5rem;
    line-height: 2.1rem;
  }

  p {
    margin-bottom: 1.5rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.c-split-section-h-content__link {
  font-family: National, serif;
  font-size: 1.3rem;
  letter-spacing: 1px;
  color: $color-aztec;
  text-decoration: none;

  &::after {
    background-color: #979797;
  }
}
