/* ==========================================================================
   #FOOTER
   ========================================================================== */

.c-footer {
  background: $color-celeste;
}

.c-footer__inner {
  padding-top: 5.5rem;
  padding-bottom: 2.8rem;
  @include bp(medium) {
    padding-top: 9.6rem;
    padding-bottom: 3.6rem;
  }
}

.c-footer-nav {
  display: none;

  @include bp(medium) {
    list-style: none;
    margin: 0 -1.5rem 5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    font-family: Canela, serif;
    font-size: 1.8rem;
    color: $color-aztec;
    letter-spacing: 0.32px;
  }
}

.c-footer-nav__li {
  margin: 0 1.5rem;

  @include bp(large) {
    margin: 0 2.25rem;
  }
}

.c-footer-nav__a {
  text-decoration: none;
  color: $color-text;
  text-transform: none;
  margin-bottom: 1rem;
}

.c-footer-low {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include bp(medium) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
}

.c-footer-low__item {
  @include bp(medium) {
    flex: 1 1 10rem;
  }
}

.c-footer-denvell {
  margin-bottom: 1rem;
  @include bp(medium) {
    margin-bottom: 0;
  }
}

.c-footer-denvell__logo {
  width: 9.6rem;
  @include bp(medium) {
    width: 11rem;
  }
}

.c-footer-ancient {
  text-align: center;
  order: -1;
  margin-bottom: 6rem;
  @include bp(medium) {
    order: initial;
    margin-bottom: 0;
  }
}

.c-footer-ancient__logo {
  width: 4.4rem;
  margin-bottom: 1.2rem;
}

.c-footer-ancient__text {
  display: block;
  font-family: Canela;
  font-size: 1.3rem;
  color: $color-aztec;
  letter-spacing: 0.65px;
  text-align: center;
  line-height: 1.9rem;
}

.c-footer-note {
  text-align: center;
  font-family: National, serif;
  color: $color-aztec;
  font-size: 1.3rem;
  @include bp(medium) {
    text-align: right;
  }
}

.c-footer-copyright {
  display: block;
  margin-bottom: 1rem;
  line-height: 2.1rem;
  @include bp(medium) {
    display: inline-block;
    margin-bottom: 0;
  }
}

.c-footer-terms {
  text-decoration: none;
  display: block;
  opacity: 0.6;
  font-weight: 300;
  line-height: 1.6rem;
  font-size: 13px;

  @include bp(medium) {
    display: inline-block;
    font-size: inherit;
  }
}
