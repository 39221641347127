.c-quote {
  width: 69.5rem;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  padding-top: 5rem;
  padding-bottom: 5rem;

  @include bp(small) {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  @include bp(medium) {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }

  @include bp(large) {
    padding-top: 23.5rem;
    padding-bottom: 23.5rem;
  }

  &.c-quote--between {
    padding-top: 1rem;
    padding-bottom: 1rem;
    @include bp(medium) {
      padding-top: 2rem;
      padding-bottom: 2rem;
    }
  }
}

.c-quote__description {
  font-family: Canela, serif;
  font-weight: 200;
  font-size: 1.8rem;
  line-height: 2.4rem;

  @include bp(small) {
    font-size: 2rem;
    line-height: 3rem;
  }

  @include bp(medium) {
    font-size: 2.7rem;
    line-height: 4.2rem;
  }
}
