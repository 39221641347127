@import 'gmap/category';

.c-gmap {
  display: flex;
  flex-direction: column;

  @include bp(xlarge) {
    flex-direction: row;
  }
}

.c-gmap__map {
  flex-direction: column;
  display: flex;
  width: 100%;
  height: 50rem;
  max-height: 100vh;

  @include bp(xlarge) {
    width: 70.778%;
    height: auto;
    min-height: calc(100vh - 83px);
    max-height: none;
    flex: 1 1 auto;
  }
}

.c-gmap__map-embed {
  flex: 1 1 auto;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.c-gmap__location-name {
  font-family: $font-sans-serif;
}

.c-gmap__locations {
  flex: 1 1 auto;
  padding: 4rem 2rem 6rem;

  @include bp(xlarge) {
    padding: 15rem 8rem;
  }
}

.c-gmap-category__subitem {
  display: flex;
  cursor: pointer;
}

.c-gmap-category__subitem-label {
  flex: 0 0 auto;
  width: 2.2em;
  pointer-events: none;
}

.c-gmap-category__subitem-title {
  pointer-events: none;
}
