.c-preloader {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 11;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $color-white;
  will-change: opacity, visibility;
  animation: fadeOutPreloader 2s forwards;

  &.active {
    animation: fadeInPreloader 1s forwards;
  }
}
