/* ==========================================================================
   #WRAPPER
   ========================================================================== */

/**
 * Page-level constraining and wrapping elements.
 */

.o-wrapper {
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  max-width: $size-wrapper;
  padding-left: $size-padding;
  padding-right: $size-padding;

  @include bp(medium) {
    padding-left: $size-padding--medium;
    padding-right: $size-padding--medium;
  }

  &--large {
    max-width: $size-wrapper--large;
  }

  &--no-padding {
    padding-left: 0;
    padding-right: 0;
  }
}
