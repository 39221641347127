.c-map {
  background-color: $color-pampas;
}

.c-map__inner {
  padding-top: 5rem;
  @include bp(medium) {
    padding-top: 10rem;
  }

  @include bp(xlarge) {
    padding-top: 18rem;
  }
}

.c-map__top {
  margin-bottom: 7rem;
  @include bp(xlarge) {
    margin-bottom: 19.5rem;
  }
}

.c-map__description {
  font-family: Canela, serif;
  font-size: 1.8rem;
  line-height: 2.4rem;
  letter-spacing: 0.3px;
  width: 770px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  @include bp(medium) {
    font-size: 2.7rem;
    line-height: 4.2rem;
    letter-spacing: 0.5px;
  }
}

.c-map__bottom {
  position: relative;
}

.c-map__image {
  width: 100%;
}

.c-map-points {
  display: none;
  @include bp(medium) {
    display: block;
    margin: 0;
    list-style: none;
  }
}

.c-map-point {
  position: absolute;
  margin: 0;
}

.c-map-point__tab {
  width: 260px;
  background: $color-pampas;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 1.5rem;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
  box-shadow: 10px 10px 20px -5px rgba(0, 0, 0, 0.5);

  .js-active & {
    opacity: 1;
    visibility: visible;
  }

  &::before,
  &:after {
    content: '';
    position: absolute;
    top: calc(100% - 1px);
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
  }

  &::before {
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-top: 7px solid $color-very-light-grey;
  }

  &::after {
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-top: 6px solid $color-pampas;
  }
}

.c-map-point__image {
  width: 100%;
  height: 150px;
  object-fit: cover;
  font-family: 'object-fit: cover; object-position: center;';
}

.c-map-point__content {
  padding: 1rem 1.5rem;
  text-align: center;
  font-family: National, serif;
  font-size: 1.3rem;
  line-height: 1.6rem;
  border: 1px solid $color-very-light-grey;
}

.c-map-point__title {
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 400;
}

.c-map-point__description {
  margin-top: 5px;
  font-weight: 300;
}

.c-map-point__dot {
  background: #a36631;
  border: 2px solid $color-white;
  display: block;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
  width: 20px;
  height: 20px;
  transform: scale(1);
  transition: transform 0.3s;
  @include bp(xlarge) {
    width: 26px;
    height: 26px;
  }

  &::after {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    width: 20px;
    height: 20px;
    border: 2px solid white;
    border-radius: 50%;
    opacity: 0;
    will-change: transform;
    animation: dotPulse 3s infinite;
    @include bp(xlarge) {
      width: 26px;
      height: 26px;
    }
  }

  &:hover {
    transform: scale(1.2);
  }
}
