.c-push-menu-overlay {
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  background: rgba(0, 0, 0, 0.5);
  transition-property: opacity, visibility;
  transition-duration: 0.3s;

  .js-push-menu--active & {
    opacity: 1;
    visibility: visible;
  }
}

.c-push-menu {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateX(-100%);
  will-change: transform;
  transition: transform 0.3s;
  background: $color-aztec;

  @include bp(small) {
    width: 500px;
    max-width: 90%;
  }

  .js-push-menu--active & {
    transform: translateX(0);
  }
}

.c-push-menu__inner {
  padding: 4rem 3.5rem;
}

.c-push-menu__content {
  position: relative;
}

.c-push-menu__close {
  display: block;
  right: 0;
  top: 0;
  width: 32px;
  height: 32px;
  position: absolute;
  cursor: pointer;

  &::before, &::after {
    position: absolute;
    left: 15px;
    content: '';
    height: 33px;
    width: 1px;
    background-color: $color-pampas;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
}

.c-push-nav {
  padding-top: 7.5rem;
  list-style: none;
  font-family: Canela-Light;
  font-size: 2.6rem;
  letter-spacing: 0.6px;
  text-align: center;
  line-height: 5.8rem;
}

.c-push-nav__li {
  margin-bottom: 0;
}

.c-push-nav__a {
  color: $color-pampas;
  text-decoration: none;
}
