.o-section {
  position: relative;

  &--screen {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    min-height: 100vh;
    padding-top: 0;
    padding-bottom: 0;
  }

  &--desktop-screen {
    @include bp(large) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      min-height: 100vh;
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  &--white {
    background-color: $color-white;
  }

  &--white-to-primary {
    background-color: $color-primary;
    @include bp(medium) {
      background-color: $color-white;
    }
  }

  &--primary-to-white {
    background-color: $color-primary;

    @include bp(medium) {
      background-color: $color-white;
    }
  }

  &--primary {
    background-color: $color-primary;
  }

  &--dark-green {
    background-color: $color-aztec;
  }

  &--light-brown {
    background-color: $color-spring-wood;
  }

  &--pampas {
    background-color: $color-pampas;
  }

  &--grey-nurse {
    background-color: $color-grey-nurse;
  }
}

.o-section__top {
  flex: 0 0 auto;
  width: 100%;
  overflow: hidden;
}

.o-section__bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.o-section__middle {
  flex: 1 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.o-section__wrapper {
  // because IE 11 min-height flexbox bug
  display: flex;
  flex-direction: column;

  > .o-section {
    width: 100%;
  }
}
