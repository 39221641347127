.o-spacer {
  padding-top: 2 * $size-padding;
  padding-bottom: 2 * $size-padding;

  &--full {
    padding-top: 0;
    padding-bottom: 0;

    @include bp(full) {
      padding-top: 11.5rem;
      padding-bottom: 11.5rem;
    }
  }
}
