.c-draft {
  padding-bottom: 7.5rem;
  margin-top: -50px;
  @include bp(medium) {
    margin-top: -100px;
    padding-bottom: 15rem;
  }
}

.c-draft__inner {

}
