.o-link {
  display: inline-block;
  font-size: 1.5rem;
  text-transform: uppercase;
  text-decoration: none;
  position: relative;
  color: inherit;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -2px;
    display: block;
    width: 100%;
    height: 1px;
    background-color: $color-aztec;
    transform: translateY(2px);
    opacity: 0;
    transition-property: transform, opacity;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
    will-change: transform, opacity;
  }

  &:hover {
    color: inherit;

    &::after {
      transform: translateY(0);
      opacity: 1;
    }
  }
}

.o-link-persist {
  display: inline-block;
  font-size: 1.5rem;
  text-transform: uppercase;
  text-decoration: none;
  position: relative;
  padding-left: 1rem;
  padding-right: 1rem;

  &::after {
    content: '';
    display: block;
    height: 1px;
    width: 100%;
    margin: auto;
    background-color: $color-light-grey;
    position: absolute;
    left: 50%;
    bottom: -8px;
    transform: translateX(-50%) scaleX(0);
    transition: transform 0.5s cubic-bezier(0.28, 0, 0.18, 1),
      background 1s cubic-bezier(0.28, 0, 0.18, 1);
  }

  &:hover {
    opacity: 1;

    &::after {
      transition: transform 0.5s cubic-bezier(0.28, 0, 0.18, 1) 0.2s;
      transform: translateX(-50%) scaleX(1);
    }
  }
}
