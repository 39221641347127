.c-home-initial {
  height: 100vh;
  padding-top: 7.5rem;
}

.c-home-initial-wrapper {
  height: 100%;
}

.c-home-initial__logo-wrapper {
  text-align: center;
  height: 6%;
}

.c-home-initial__gap {
  height: 10%;
  @include bp(small) {
    height: 9%;
  }
}

.c-home-initial__image-wrapper {
  text-align: center;
  height: 85%;
  padding-bottom: 12rem;
  @include bp(large) {
    padding-bottom: 14rem;
  }
}

.c-home-initial__logo {
  max-height: 100%;
}

.c-home-initial__image {
  max-height: 100%;
}
