/* ==========================================================================
   #FONT-FACE
   ========================================================================== */

/**
 * Add font-display: swap to font-face blocks here
 * https://www.zachleat.com/web/comprehensive-webfonts/#font-display
 */

$font-base: '../assets/fonts/';

@mixin font($name, $file, $style: normal, $weight: 400) {
  @font-face {
    font-family: $name;
    src: url($font-base+$file+'.woff') format('woff');

    font-style: $style;
    font-weight: $weight;
    font-display: swap;
  }
}

@include font($name: 'Canela', $file: 'Canela-Light-Web', $weight: 200);

@include font($name: 'National', $file: 'NationalWeb-Thin', $weight: 100);
@include font(
  $name: 'National',
  $file: 'NationalWeb-Book',
  $style: normal,
  $weight: 300
);
@include font(
  $name: 'National',
  $file: 'NationalWeb-BookItalic',
  $style: italic,
  $weight: 300
);
@include font($name: 'National', $file: 'NationalWeb-Regular', $weight: 400);
@include font($name: 'National', $file: 'NationalWeb-Semibold', $weight: 600);
