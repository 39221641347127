.c-palette {
  padding-top: 4.5rem;
  padding-bottom: 4.5rem;
  transition-property: color, background-color;
  transition-duration: 0.3s;
  @include bp(medium) {
    padding-top: 11rem;
    padding-bottom: 7.5rem;
  }

  &.js-light {
    color: $color-aztec;
    background-color: $color-pampas;
  }

  &.js-dark {
    color: $color-pampas;
    background-color: #7e7a73;
  }
}

.c-palette__inner {
}

.c-palette__top {
  text-align: center;
  margin-bottom: 3.5rem;
  width: 740px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;

  @include bp(small) {
    margin-bottom: 6rem;
  }

  @include bp(medium) {
    margin-bottom: 12rem;
  }
}

.c-palette__title {
  font-size: 1.8rem;
  letter-spacing: 0.3px;
  line-height: 2.4rem;
  font-family: Canela, serif;
  font-weight: 200;
  margin-bottom: 0;

  @include bp(small) {
    font-size: 2.3rem;
    line-height: 3rem;
  }

  @include bp(medium) {
    font-size: 2.7rem;
    letter-spacing: 0.5px;
    line-height: 4.2rem;
  }
}

.c-palette-switcher {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4.5rem;
  cursor: pointer;
}

.c-palette-switcher__label {
  text-transform: uppercase;
  font-size: 1.3rem;
  line-height: 1.6rem;
  letter-spacing: 1px;
  font-weight: 400;
}

.c-palette-switch {
  margin-left: 3rem;
  margin-right: 3rem;
  position: relative;
}

.c-palette-switch__line {
  display: block;
  width: 55px;
  border-top: 1px solid $color-very-light-grey;
}

.c-palette-switch__dot {
  position: absolute;
  right: 100%;
  top: 50%;
  transform: translateY(-50%);
  display: block;
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 50%;
  transition-property: right, background-color;
  transition-duration: 0.3s;

  .js-light & {
    right: 100%;
    border: 1px solid $color-aztec;
    background-color: $color-pampas;
  }

  .js-dark & {
    right: -16px;
    border: 1px solid $color-pampas;
    background-color: #7e7a73;
  }
}

.c-palette__shades {
  position: relative;
}

.c-palette__bottom {
  transition-property: opacity, visibility;
  transition-duration: 0.3s;

  &.c-palette__bottom--light {
    .js-dark & {
      opacity: 0;
      visibility: hidden;
    }

    .js-light & {
      opacity: 1;
      visibility: visible;
    }
  }

  &.c-palette__bottom--dark {
    color: $color-pampas;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .js-dark & {
      opacity: 1;
      visibility: visible;
    }

    .js-light & {
      opacity: 0;
      visibility: hidden;
    }
  }
}

.c-palette-overview {
  margin-bottom: 3rem;

  @include bp(small) {
    margin-bottom: 6rem;
  }

  @include bp(medium) {
    margin-bottom: 10rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.c-palette-overview__image-wrapper {
  text-align: center;

  @include bp(medium) {
    flex: 0 1 47rem;
    text-align: left;
  }
}

.c-palette-overview__image {
  width: 100%;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;

  @include bp(medium) {
    max-width: 100%;
  }
}

.c-palette-overview__content {
  text-align: center;
  font-family: National, serif;
  margin-top: 3.5rem;

  @include bp(medium) {
    margin-left: 7rem;
    flex: 0 1 28rem;
    margin-top: 0;
  }

  @include bp(large) {
    margin-left: 15rem;
  }
}

.c-palette-overview__title {
  font-size: 1.3rem;
  letter-spacing: 1.15px;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 2rem;

  @include bp(small) {
    margin-bottom: 5.5rem;
    font-size: 1.5rem;
  }
}

.c-palette-overview__description {
  font-weight: 300;
  font-size: 1.3rem;
  line-height: 1.9rem;

  @include bp(small) {
    font-size: 1.5rem;
    line-height: 2.1rem;
  }
}

.c-palette-slider {
  list-style: none;
  margin: 0;

  .flickity-page-dots {
    bottom: -30px;

    .dot {
      background: transparent;
      border: 1px solid $color-grey;
      opacity: 1;

      &.is-selected {
        background: $color-very-light-grey;
      }
    }

    @include bp(small) {
      display: none;
    }
  }

  .flickity-prev-next-button.previous {
    display: none;

    @include bp(medium) {
      display: block;
      left: $size-padding;
    }

    @include bp(large) {
      left: $size-padding--medium;
    }
  }

  .flickity-prev-next-button.next {
    display: none;

    @include bp(medium) {
      display: block;
      right: $size-padding;
    }

    @include bp(large) {
      right: $size-padding--medium;
    }
  }
}

.c-palette-slide {
  width: 85%;
  margin-left: 0.75rem;
  margin-right: 0.75rem;

  @include bp(small) {
    display: block;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    width: 75%;
  }

  @include bp(large) {
    width: 66.66%;
    margin-left: 3rem;
    margin-right: 3rem;
  }
}

.c-palette-slide__top {
}

.c-palette-slide__image {
  width: 100%;
}

.c-palette-slide__bottom {
  display: block;
  font-family: National, serif;
  margin-top: 2rem;

  @include bp(small) {
    margin-top: 3rem;
  }

  @include bp(medium) {
    display: flex;
  }
}

.c-palette-slide__content {
  flex: 0 0 50%;

  @include bp(medium) {
    padding-right: 6rem;
  }
}

.c-palette-slide__title {
  font-weight: 400;
  letter-spacing: 1.15px;
  text-transform: uppercase;
  font-size: 1.3rem;
  margin-bottom: 0;

  @include bp(small) {
    margin-bottom: 0;
    font-size: 1.5rem;
  }
}

.c-palette-slide__description {
  font-weight: 300;
  letter-spacing: 0;
  opacity: 0.6;
  font-size: 1.3rem;
  line-height: 1.9rem;
}
