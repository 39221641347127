.c-screen-image {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;

  &.c-screen-image--darker {
    background: $color-black;
  }

  &.c-screen-image--aztec {
    background: $color-black;
  }
}

.c-screen-image__wrapper {
  width: 100%;
  height: 100%;
}

.c-screen-image__image {
  position: absolute;
  max-width: none;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover; object-position: center;';
  left: 0;
  top: 0;
  transform: none;

  .c-screen-image--darker & {
    opacity: 0.7;
  }
}
