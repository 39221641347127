/* stylelint-disable */

.c-contact {
  background-color: $color-pampas;
}

.c-contact__inner {
  padding-top: 13.5rem;
  padding-bottom: 5rem;

  @include bp(medium) {
    padding-top: 15rem;
  }

  @include bp-down(medium) {
    width: 500px;
    max-width: 100%;
  }
}

.c-contact__top {
  margin-bottom: 5rem;
  @include bp(medium) {
    margin-bottom: 7.5rem;
  }
}

.c-contact__title {
  font-size: 1.8rem;
  line-height: 2.4rem;
  letter-spacing: 0.3px;

  @include bp(small) {
    font-size: 2.2rem;
    line-height: 3rem;
    text-align: center;
  }

  @include bp(medium) {
    font-size: 2.7rem;
    line-height: 4.2rem;
    letter-spacing: 0.5px;
  }
}

.c-contact-confirmation {
  background: $color-aztec;
  color: $color-white;
  padding-top: 4rem;
  padding-bottom: 4rem;
  text-align: center;

  @include bp(small) {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  @include bp(medium) {
    padding-top: 12rem;
    padding-bottom: 11rem;
  }
}

.c-contact-confirmation__content {
  width: 340px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.c-contact-confirmation__title {
  font-size: 2.7rem;
  line-height: 4.2rem;
  margin-bottom: 2.5rem;
}

.c-contact-confirmation__quote {
  margin-bottom: 5rem;
  display: block;
  font-style: italic;
  font-size: 1.6rem;
  line-height: 1.9rem;
}

.c-contact-confirmation__description {
  font-size: 1.5rem;
  line-height: 2.1rem;
  margin-bottom: 4rem;
}

.c-contact-confirmation__link {
  color: $color-white;
}

.c-contact__bottom {
}

.c-contact__note {
  text-align: center;
  font-weight: 300;
  font-size: 1.3rem;
  line-height: 1.6rem;
  width: 480px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  opacity: 0.6;
}

.c-contact__footer {
  margin-top: 3rem;
  border-top: 1px solid $color-very-light-grey;
  padding-top: 3.5rem;

  @include bp(medium) {
    margin-top: 8rem;
    padding-top: 2rem;
    display: flex;
    justify-content: center;
  }
}

.c-contact-place {
  font-size: 1.5rem;
  line-height: 2.1rem;
  font-weight: 300;

  @include bp(medium) {
    text-align: center;
    margin-left: 6rem;
    margin-right: 6rem;
  }

  &:first-child {
    margin-bottom: 3rem;

    @include bp(medium) {
      margin-bottom: 0;
    }
  }

  > span {
    display: block;
  }
}

.c-contact-place__title {
  text-transform: uppercase;
  font-weight: 400;
}

.c-contact-place__country {
  text-transform: uppercase;
}

div.wpforms-container .wpforms-form .wpforms-field-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: -2rem;
  margin-right: -2rem;
}

div.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field {
  flex: 0 0 auto;
  width: 100%;
  margin: 0;
  padding: 0 2rem 2.5rem;

  @include bp(medium) {
    width: 50%;
    padding: 0 2rem 3.5rem;
  }

  @include bp(large) {
    width: 33.33%;
  }

  input,
  select,
  label {
    background-color: transparent;
    max-width: none;
    margin: 0;
    padding: 0;
    border: none;
    border-radius: 0;
    font-size: 1.3rem;
    line-height: 1.7rem;
    font-family: National, serif;
    font-weight: 300;
    color: $color-aztec;

    @include bp(small) {
      font-size: 1.5rem;
      line-height: 2.1rem;
    }

    &::placeholder {
      opacity: 1;
      color: $color-aztec;
    }
  }

  input[type='text'],
  input[type='email'],
  select {
    border-bottom: 1px solid $color-very-light-grey;
  }

  select {
    -webkit-appearance: none;
    background-image: url('../assets/images/contact/chevron-down.svg');
    background-repeat: no-repeat;
    background-position: center right;
    background-size: 20px 10px;
  }

  select::-ms-expand {
    display: none;
  }

  input[type='checkbox'] {
    display: none;
  }
}

div.wpforms-container
.wpforms-form
.wpforms-field-container
.wpforms-field-checkbox
ul
li {
  position: relative;

  &::before,
  &::after {
    position: absolute;
    pointer-events: none;
  }

  &::before {
    display: block;
    content: '';
    width: 2.2rem;
    height: 2.2rem;
    border: 1px solid $color-very-light-grey;
    z-index: 1;
    background: $color-pampas;
    left: 0;
    top: 0;
  }

  &.wpforms-selected {
    &::after {
      content: '';
      display: block;
      width: 7px;
      height: 14px;
      border: solid $color-light-grey;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      z-index: 2;
      left: 7px;
      top: 3px;
    }
  }

  label {
    padding-left: 3.5rem;
    cursor: pointer;
    display: block;
    @include bp(medium) {
      padding-left: 5rem;
    }
  }
}

div.wpforms-container .wpforms-form .wpforms-submit-container {
  text-align: center;

  button[type='submit'].c-contact-form__submit {
    font-size: 1.3rem;
    line-height: 1.6rem;
    font-family: National, serif;
    background: transparent;
    border: none;
    border-bottom: 1px solid $color-light-grey;
    text-transform: uppercase;
    padding: 0 1rem 0.2rem;

    &:hover {
      background: transparent;
      border: none;
      border-bottom: 1px solid $color-light-grey;
    }
  }
}

/* stylelint-enable */
