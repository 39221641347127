.c-title-text {
  width: 460px;
  max-width: 100%;
  text-align: center;
  padding-top: 4.5rem;
  padding-bottom: 4rem;
  @include bp(medium) {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }
  @include bp(large) {
    padding-top: 20rem;
    padding-bottom: 20rem;
  }
}

.c-title-text__heading {
  text-transform: uppercase;
  font-size: 1.3rem;
  margin-bottom: 0;
  font-weight: 400;

  @include bp(medium) {
    font-size: 1.5rem;
  }
}

.c-title-text__line {
  display: inline-block;
  height: 33px;
  width: 1px;
  background-color: #979797;
  margin-top: 15px;
  margin-bottom: 15px;
}

.c-title-text__paragraphs {
  font-size: 1.3rem;
  font-weight: 300;
  font-family: National;
  font-style: normal;

  p {
    margin-bottom: 1.5rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @include bp(medium) {
    font-size: 1.5rem;
    line-height: 2.1rem;
  }
}
