.c-image-section {
}

.c-image-section__container {
  width: 100%;
}

.c-image-section__image {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  font-family: 'object-fit: cover; object-position: center;';
}
