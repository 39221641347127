.c-hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
  min-height: calc(100vh - 60px);

  @include bp(large) {
    margin-top: 83px;
    min-height: calc(100vh - 83px);
  }

  &--decorated {
    background: linear-gradient(
      to top,
      rgba($color-black, 0.65),
      transparent 15%
    );
  }

  &--no-header {
    margin-top: 0;
    min-height: 100vh;
  }
}

.c-hero__intro {
  max-width: 76.9rem;
  margin-bottom: 6rem;
  font-family: $font-sans-serif;
  font-weight: 300;
  font-size: 1.8rem;
  text-align: center;

  @include bp(medium) {
    font-family: $font-heading;
    font-size: 2.7rem;
  }

  &--light {
    color: $color-white;
  }

  &--dark {
    color: $color-text;
  }
}

.c-hero__link {
  color: inherit;
  margin-bottom: 0.2rem;
}

.c-hero__heading {
  font-size: 3.9rem;
  font-weight: 200;
  text-align: center;

  @include bp(medium) {
    font-size: 5.7rem;
  }

  &--light {
    color: $color-white;
  }

  &--dark {
    color: $color-text;
  }
}

.c-hero__decoration {
  margin: auto;
  font-size: 1.5rem;
  font-weight: 500;
  color: $color-white;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.15px;

  &::after {
    content: '';
    display: block;
    width: 1px;
    height: 5rem;
    margin: 1rem auto auto;
    background-color: $color-white;

    @include bp(large) {
      height: 7.4rem;
    }
  }
}

.c-hero__top {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10rem;

  @include bp(large) {
    padding-top: 22rem;
    padding-bottom: 10rem;
  }
}

