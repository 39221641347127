/* ==========================================================================
   #BUTTONS
   ========================================================================== */

/**
 * This is an example component. Extend inuitcss by building your own components
 * that make up your UI. Component classes are prefixed with a `c-`.
 */

/**
 * 1. Allow us to style box model properties.
 * 2. Line different sized buttons up a little nicer.
 * 3. Make buttons inherit font styles (often necessary when styling `input`s as
 *    buttons).
 * 4. Reset/normalize some styles.
 * 5. Force all button-styled elements to appear clickable.
 */

.c-btn {
  display: inline-block; /* [1] */
  padding: 1.7rem 4.1rem;
  margin: 0; /* [4] */

  font: inherit; /* [3] */
  font-size: 1.5rem;
  letter-spacing: 1.15px;
  text-align: center; /* [4] */
  vertical-align: middle; /* [2] */
  cursor: pointer; /* [5] */
  text-transform: uppercase;
  text-decoration: none;

  background-color: $color-white;
  color: $color-text;
  transition: $global-transition;
}
