.c-reviews {
  padding-top: 6.7rem;
  padding-bottom: 6.7rem;

  @include bp(small) {
    padding-bottom: 8rem;
  }

  @include bp(medium) {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }

  @include bp(large) {
    padding-top: 20rem;
    padding-bottom: 20rem;
  }
}

.c-reviews__carousel {
  width: 154rem;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;

  .flickity-button {
    display: none;

    @include bp(medium) {
      display: block;
      height: 35px;
      width: 35px;
    }

    @include bp(large) {
      height: 50px;
      width: 50px;
    }
  }

  .flickity-page-dots {
    bottom: -50px;

    @include bp(medium) {
      display: none;
    }

    .dot {
      border: 1px solid #979797;
      background: transparent;
      opacity: 1;
      width: 1rem;
      height: 1rem;
      margin: 0 5px;

      &.is-selected {
        background: #d8d8d8;
      }
    }
  }
}

.c-review {
  width: 100%;

  @include bp(medium) {
    padding-left: 6rem;
    padding-right: 6rem;
  }

  @include bp(large) {
    padding-left: 14rem;
    padding-right: 14rem;
  }
}

.c-review__inner {
  @include bp(medium) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.c-review__left {
  text-align: center;
  margin-bottom: 45px;

  @include bp(medium) {
    width: 30%;
    margin-bottom: 0;
    text-align: left;
    flex: 1 1 auto;
    margin-right: 1.5rem;
  }

  @include bp(xlarge) {
    width: (10/21) * 100%;
    flex: 0 1 auto;
  }
}

.c-review__image {
  width: 100%;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;

  @include bp(medium) {
    max-width: 590px;
  }
}

.c-review__right {
  text-align: center;

  @include bp(medium) {
    flex: 1 1 auto;
    max-width: 48rem;
    margin-left: 1.5rem;
  }
}

.c-review__content {
  color: $color-white;
  text-align: center;

  @include bp(medium) {
    max-width: 473px;
    margin-left: auto;
  }
}

.c-review__quote {
  font-family: Canela;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.3px;

  @include bp(medium) {
    font-size: 23px;
    line-height: 30px;
    letter-spacing: 0.4px;
  }

  @include bp(large) {
    font-size: 27px;
    line-height: 42px;
    letter-spacing: 0.5px;
  }
}

.c-review__author {
  display: block;
  letter-spacing: 1.15px;
  font-size: 15px;
  line-height: 19px;
  text-transform: uppercase;
  font-weight: 400;
  margin-top: 25px;
}
