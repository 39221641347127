.c-header {
  // stylelint-disable-next-line
  body.home & {
    height: 6rem;

    @include bp(large) {
      height: 8rem;
    }
  }
}

.c-header__inner {
  background-color: $color-white;

  .js-header-fixed & {
    position: fixed;
    z-index: 8;
    top: 0;
    left: 0;
    width: 100%;
  }
}

.c-header__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 2rem;
  padding-bottom: 2rem;

  @include bp(large) {
    padding-top: 3rem;
    padding-bottom: 2.8rem;
  }
}

.c-header-nav {
  display: none;
  @include bp(large) {
    flex: 1;
    display: flex;
    list-style: none;
    margin: 0;
    font-family: National, serif;
    font-weight: 400;
    font-size: 1.5rem;
    letter-spacing: 1.15px;
    text-transform: uppercase;
  }

  &.c-header-nav--left {
  }

  &.c-header-nav--right {
    justify-content: flex-end;
  }
}

.c-header-nav__li {
  @include bp(large) {
    margin-bottom: 0;
    margin-right: 1.5rem;

    &:last-child {
      margin-right: 0;
    }
  }

  @include bp(xlarge) {
    margin-right: 3rem;
  }
}

.c-header-nav__a {
  text-decoration: none;
  color: $color-aztec;
}

.c-header-logo {
  flex: 0 0 134px;

  @include bp(xlarge) {
    flex: 0 0 176px;
  }
}

.c-header-logo__image {
  width: 100%;
}

.c-header-space-keeper {
  display: block;
  flex: 1;
  @include bp(large) {
    display: none;
  }
}

.c-header-hamburger {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  @include bp(large) {
    display: none;
  }
}

.c-header-hamburger__shape {
  display: block;
  width: 3rem;
  cursor: pointer;
  padding: 0.3rem 0 0.3rem 0.5rem;
}

.c-header-hamburger__shape-line {
  display: block;
  width: 2rem;
  height: 0.2rem;
  background: $color-black;

  &.c-header-hamburger__shape-line--center {
    margin-top: 4px;
    margin-bottom: 4px;
  }
}
